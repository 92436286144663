@import url('https://fonts.googleapis.com/css?family=Satisfy');
@import url('https://fonts.googleapis.com/css?family=Dancing+Script');
@import url('https://fonts.googleapis.com/css?family=Poppins:400,500&display=swap');
/* @import url('https://fonts.googleapis.com/css?family=Poppins&display=swap'); */

body {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  color: #002a57;
}

.App {
  height: 100vh;
  margin: 0;
  font-size: 16px;
  /* font-family:"Lato", sans-serif; */
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 1.8em;
  /* color:#666; */
}

.pimg {
  /* position: relative; */
  opacity: 1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /*
    fixed = parallax
    scroll = normal
  */
  background-attachment: fixed;
  min-height: 100vh;
  max-width: 100vw;

}

.section {
  text-align: center;
  padding: 40px 0px 32px 0px;
}

.section-light {
  /* background-color:#f4f4f4; */
  /* color:#666; */
  max-width: 100vw;
  overflow: hidden;
}

.section-light .my-header {
  background-image: -webkit-linear-gradient(20deg, #e7e7e7 50%, rgba(0, 0, 0, 0) 0%);
}

.section-light2 {
  /* background-color:#f4f4f4; */
  /* color:#666; */
  max-width: 100vw;
  overflow: hidden;
}

.section-light2 .my-header {
  background-image: -webkit-linear-gradient(20deg, rgba(0, 0, 0, 0) 50%, #e7e7e7 0%);
}

.section-light-about {
  /* background-color:#f4f4f4; */
  /* color:#666; */
  background-image: -webkit-linear-gradient(20deg, rgba(0, 0, 0, 0) 50%, #e7e7e7 0%);
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 50px 0px 3px 0px;
}

.section-dark {
  background-color: #282e34;
  color: #ddd;
}

.ptext {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  color: #000;
  font-size: 1em;
  letter-spacing: 8px;
  text-transform: uppercase;
}

.ptext-quote {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  color: #000;
  font-size: 1em;
}

.ptext .borders {
  background-color: #002a57;
  color: #fff;
  padding: 20px;
  font-weight: bold;
}

.borders:hover{
  background-color: white;
  color: black;
  padding: 30px;
  border-color: #002a57;
  border-width: 10px;
  border-style: solid;
}

.ptext .border.trans {
  background-color: transparent;
}

.ptext .border-rev {
  background-color: #fff;
  color: #002a57;
  padding: 20px;
  border: 2px solid #002a57;
  font-weight: bold;
}

.my-header {
  font-size: 3em;
  /* text-decoration: underline; */
  text-transform: uppercase;
  /* border: 40px solid black; */
  /* width: 50%;
  margin-left: 25%; */
  /* float: right; */
  /* width: 100vw; */
  /* height: 10.8vh; */
  line-height: 8vh;
  /* border-width: 53px; */
  padding: 10px;
  font-weight: 900;
  /* letter-spacing: 4PX; */
  letter-spacing: 2px;
  /* padding: 2vh 0vh 3vh 0vh; */
  font-size: 2em;
}

.quote-container {
  font-family: 'Satisfy', 'Dancing Script', serif;
  font-size: 2.7vw;
  color: #303633;
  font-weight: 600;
  line-height: 80px;
  width: 80%;
  border-radius: 25px;
  /* text-indent: 105px; */
  margin-left: 10%;
  background-color: white;
  height: 166px;
  margin-top: -83px;
  position: relative;
  text-transform: none !important;
}

.quote-container:before {
  content: '\201C';
  font-family: serif;
  font-style: normal;
  font-weight: 900;
  position: absolute;
  font-size: 175px;
  top: -11px;
  left: -105px;
  padding-left: 25px;
  color: white;
}

@media(max-width:320px) {
  .border-rev {
    font-size: 8px;
  }
}
@media(min-width:769px) and (max-width:1024px){
  .pimg , .pimgProject {
    background-attachment: scroll;
  }
}
@media(max-width:1366px) and (max-height:905px){
  .pimg , .pimgProject {
    background-attachment: scroll;
  }
}
@media(max-width:768px) {
  .pimg , .pimgProject {
    background-attachment: scroll;
  }
  .ptext2 {
    margin-top: 40px;
  }
  .ptext {
    font-size: 2.9vw;
    letter-spacing: 2.6px
  }
  .section {
    text-align: center;
    padding: 15px 0px 6px 0px;
  }
  #whatWeDo {
    padding-bottom: 23px !important;
  }
  .my-header {
    font-size: 1.5em;
    text-transform: uppercase;
    /* width: 100vw; */
    /* width: 90%;
    margin-left: 0%; */
    /* height: 7.5vh; */
    line-height: 5vh;
    font-weight: 900;
    letter-spacing: 1px;
  }
  .pimg .quote-container {
    font-family: 'Satisfy', 'Dancing Script', serif;
    font-size: 7vw;
    color: #303633;
    font-weight: 600;
    line-height: 65px;
    width: 80%;
    border-radius: 25px;
    /* text-indent: 105px; */
    margin-left: 10%;
    background-color: white;
    height: 250px;
    margin-top: -125px;
    position: relative;
    text-transform: none !important;
  }
  .pimg3 .quote-container {
    font-family: 'Satisfy', 'Dancing Script', serif;
    font-size: 6.5vw;
    color: #303633;
    font-weight: 600;
    line-height: 65px;
    width: 80%;
    border-radius: 25px;
    /* text-indent: 105px; */
    margin-left: 10%;
    background-color: white;
    height: 320px !important;
    margin-top: -160px;
    position: relative;
    text-transform: none !important;
  }
  .quote-container:before {
    content: '\201C';
    font-family: serif;
    font-style: normal;
    font-weight: 900;
    position: absolute;
    font-size: 120px;
    top: -30px;
    left: -40px;
    padding-left: 25px;
    color: white;
  }
  .cardsCont {
    font-weight: 500;
    /* font-size: 1.5vw; */
    display: flex;
    justify-content: center;
    /* text-align: justify; */
    font-family: 'Poppins-Light';
  }
  .homeContainer {
    padding: 1vh;
  }
  .leftColMain {
    padding: 1vh !important;
  }
  .serviceImageCol{
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .serviceImageColSecondRow{
    padding-top: 0px !important;
  }
}

@media(max-width:350px) {
  .cardsCont {
    font-weight: 500;
    display: flex;
    justify-content: center;
    /* text-align: justify; */
    font-family: 'Poppins-Light';
  }
  .pimg .quote-container {
    font-family: 'Satisfy', 'Dancing Script', serif;
    font-size: 7vw;
    color: #303633;
    font-weight: 600;
    line-height: 65px;
    width: 90%;
    border-radius: 25px;
    /* text-indent: 105px; */
    margin-left: 5%;
    background-color: white;
    height: 250px;
    margin-top: -125px;
    position: relative;
    text-transform: none !important;
  }
  .pimg3 .quote-container {
    font-family: 'Satisfy', 'Dancing Script', serif;
    font-size: 6.5vw;
    color: #303633;
    font-weight: 600;
    line-height: 65px;
    width: 90%;
    border-radius: 25px;
    /* text-indent: 105px; */
    margin-left: 5%;
    background-color: white;
    height: 320px;
    margin-top: -160px;
    position: relative;
    text-transform: none !important;
  }
  .quote-container:before {
    content: '\201C';
    font-family: serif;
    font-style: normal;
    font-weight: 900;
    position: absolute;
    font-size: 120px;
    top: -30px;
    left: -40px;
    padding-left: 25px;
    color: white;
  }
  .Logo {
    margin-right: 5px;
  }
  .homeContainer {
    padding: 1vh;
  }
  .leftColMain {
    /* padding: 1vh !important; */
  }
  .serviceImageCol{
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .serviceImageColSecondRow{
    padding-top: 0px !important;
  }
}

.leftColMain {
  /* background-color: green; */
  text-align: center;
  /* padding: 6vh; */
}

.ourMotoClass {
  /* background-color: green; */
  text-align: center;
  padding-top: 10vh;
  /* padding: 6vh; */
}

.ourMotoClassRow {
  text-align: center;
}



.homeContainer {
  margin-top: 3vh;
  margin-bottom: 3vh;
  padding: 3vh;
  max-width: 100vw;
  overflow: hidden;
}

/* big screen */

.cardsCont {
  font-weight: 500;
  /* font-size: 1.5vw; */
  /* text-align: justify; */
  display: flex;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
  font-size: 1em;
  line-height: 40px;
  /* min-height: 26vh; */
  border-radius: 25px !important;
  letter-spacing: 1px;
  background-color: #fcde58 !important;
  border-width: 3px;
  /* background-color: greenyellow !important; */
  -webkit-font-smoothing: antialiased;
  /* transform: skewX(17deg); */
}

.cardsCont:hover{
  background-color: white !important;
  /* color: black; */
  border-color: #fcde58;
  border-width: 3px;
  /* padding: 30px; */
  border-style: solid;
}

.cardsCont>.card-body {
  /* transform: skewX(-30deg) !important; */
}

.lastHeading {
  /* min-height: 80vh !important; */
}

.serviceImageCol{
  padding-left: 40px;
  padding-right: 40px;
}

.serviceImageColSecondRow{
  padding-top: 40px;
}

.snip1321 figcaption{
  transform: translateY(-50%);
  transition: none;
  visibility: hidden;;
}

.snip1321:hover figcaption, .snip1321.hover figcaption {
  transition-delay: unset !important;
  visibility: visible;
  -webkit-animation: fadein 2s;
  -moz-animation: fadein 2s; 
   -ms-animation: fadein 2s; 
    -o-animation: fadein 2s; 
       animation: fadein 2s;
}






.clientsImg {
  /* vertical-align:middle; */
  /* height: 100%; */
  /* background-color: white; */
  /* height: 200px; */
  /* min-height: 200px; */
  /* max-height: 200px; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* padding: 5px; */
  /* overflow: hidden; */
}

.slideInRight{
  max-width: 100vw;
  overflow: hidden;
}


.ourServiceMainRow{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    /* height: 100vh; */
    padding: 5vh 0vh 5vh 0vh;
}
.ourClientMainRow{
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /* height: 60vh; */
  padding: 5vh 0vh 5vh 0vh;
}

.pimg3{
  min-height: 0vh !important;
}

figcaption > h4{
  font-size: 1.5em;
}

figcaption > h2{
  font-size: 1em;
}

.ourClientsCol{
  display: flex;
  justify-content: center;
}

.svg-inline--fa {
  vertical-align: -0.450em !important;
}


 .borders{
  /* antimated trantitions for all elements */
  -webkit-transition: 0.5s;
  /* For Safari 3.1 to 6.0 */
  transition: 0.5s;
}

.svg-inline--fa.fa-w-10 {
  margin-top: 15%;
}