.no-body-scroll{
  height: 100%;
  width: 100%;
  overflow: hidden !important;
  position: fixed !important;
}

/* body{
  color: #002a57 !important;
} */

.AboutMain{
  height:100vh;
  margin:0;
  font-size:16px;
  font-family:"Lato", sans-serif;
  font-weight:400;
  line-height:1.8em;
  color:#002a57;
  /* overflow: scroll;
  position: fixed; */
}

.ptext-aboutMessage{
  top: 70%;
  width: 88%;
  margin-left: 6%;
  text-align: justify;
  font-size: 1.3em;
  /* font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif; */
  font-family: 'Poppins', sans-serif;
  /* font-weight: bold; */
  line-height: 45px;
  background: white;
  border-radius: 25px;
  color: 002a57;
  padding: 30px 30px 30px 30px;
}

.about-heading{
  position:absolute;
  top:50%;
  width: 100%;
  text-align:center;
  color:#000;
  font-size:2vw;
  letter-spacing:8px;
  text-transform:uppercase;
}

.about-heading .borders{
  background-color:#111;
  color:#fff;
  padding:20px;
}

.my-header-main{
    /* text-decoration: underline; */
    text-transform: uppercase;
    /* border: 40px solid black; */
    width: 80%;
    margin-left: 10%;
    /* float: right; */
    height: 20px;
    line-height: 0px;
    font-weight: bold;
    letter-spacing: 2px;
    margin-top: -20px;
    /* padding: 2vh 0vh 3vh 0vh; */
    font-size: 2rem !important;
}

.my-header-sub{
  /* text-decoration: underline; */
  text-transform: uppercase;
  /* border: 40px solid black; */
  width: 80%;
  margin-left: 10%;
  /* float: right; */
  height: 20px;
  line-height: 0px;
  font-weight: bold;
  letter-spacing: 2px;
  margin-top: -20px;
  /* padding: 2vh 0vh 3vh 0vh; */
  font-size: 2rem !important;
}

@media(max-width:768px){
  .my-header-main{
    /* font-size: 6vw; */
    /* text-decoration: underline; */
    text-transform: uppercase;
    /* border: 40px solid black; */
    width: 80%;
    margin-left: 10%;
    /* float: right; */
    height: 7vh;
    line-height: 5vh;
    /* border-width: 53px; */
    padding: 10px;
    font-weight: 900;
    letter-spacing: 4PX;
    font-size: 1.4em !important;
  }
  
  .my-header-sub{
    font-size: 6vw;
    /* text-decoration: underline; */
    text-transform: uppercase;
    /* border: 40px solid black; */
    width: 80%;
    margin-left: 10%;
    /* float: right; */
    height: 7vh;
    line-height: 5vh;
    /* border-width: 53px; */
    padding: 10px;
    font-weight: 900;
    letter-spacing: 4PX;
    font-size: 1.4em !important;
  }

  .ptext-aboutMessage{
    /* font-size: 3.5vw; */
    padding:6px;
    font-size: 1em;
    line-height: 30px;
    /* text-align: justify; */
    
  }  

  .team{
    height: auto !important;
  }
}

@media(max-width:568px){
  .my-header-main{
    /* font-size: 6vw; */
    /* text-decoration: underline; */
    text-transform: uppercase;
    /* border: 40px solid black; */
    width: 80%;
    margin-left: 10%;
    /* float: right; */
    height: 7vh;
    line-height: 7vh;
    /* border-width: 53px; */
    padding: 10px;
    font-weight: 900;
    letter-spacing: 4PX;
    font-size: 1.4rem !important;
  }
  
  .my-header-sub{
    font-size: 6vw;
    /* text-decoration: underline; */
    text-transform: uppercase;
    /* border: 40px solid black; */
    width: 80%;
    margin-left: 10%;
    /* float: right; */
    height: 7vh;
    line-height: 5vh;
    /* border-width: 53px; */
    padding: 10px;
    font-weight: 900;
    letter-spacing: 4PX;
    font-size: 1.4rem !important;
  }

  .ptext-aboutMessage{
    /* font-size: 3.5vw; */
    padding:6px;
    font-size: 1em;
    line-height: 30px;
  }  

  .team{
    height: auto !important;
  }
}

.leftCard{
  display: inline-block;
  background: tomato;
  padding: 5px;
  width: 500px;
  height: 500px;
  margin-top: 10px;
  margin: 50px;
  
}

.team{
  /* height: 100vh; */
    /* width: 100vw; */
    /* bottom: 0; */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 30px; */
    text-align: center;
    
}


.teamSay{
  /* height: 100vh; */
    /* width: 100vw; */
    /* bottom: 0; */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}



.otherSpec{
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}


/* can remove later */
.AboutBase {
  /* margin-top: 100px; */
  margin-bottom: 100px;
  margin-left: 5% ;
  margin-right: 5% ;
  font-size:16px;
  /* font-family:"Lato", sans-serif; */
  font-family: 'Poppins', sans-serif;
  font-weight:400;
  line-height:1.5em;
  color:#002a57
  ;
}
/* @import url('compass/css3'); */

.flex-container {
  padding: 10px;
  margin: 0;
  list-style: none;
  
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  
  
  justify-content: space-around;
}

.flex-item {
  background: tomato;
  padding: 5px;
  width: 200px;
  height: 150px;
  margin-top: 10px;
  
  
  line-height: 150px;
  color: white;
  font-weight: bold;
  font-size: 3em;
  text-align: center;
  }

  
.flex-item-q {
  background: blue;
  padding: 5px;
  width: 600px;
  height: 150px;
  margin-top: 10px;
  
  
  line-height: 150px;
  color: white;
  font-weight: bold;
  font-size: 3em;
  text-align: center;
  }

 #whatWeDo{
   padding-bottom: 50px;
 }