@import url(https://fonts.googleapis.com/css?family=Satisfy);
@import url(https://fonts.googleapis.com/css?family=Dancing+Script);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
@import url(https://fonts.googleapis.com/css?family=Teko:700);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,800);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display:400,800);
@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
@import url(https://fonts.googleapis.com/css?family=Raleway);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:600;);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue","Varela Round"
    sans-serif "Poppins"; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}




/* @import url('https://fonts.googleapis.com/css?family=Poppins&display=swap'); */

body {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  color: #002a57;
}

.App {
  height: 100vh;
  margin: 0;
  font-size: 16px;
  /* font-family:"Lato", sans-serif; */
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 1.8em;
  /* color:#666; */
}

.pimg {
  /* position: relative; */
  opacity: 1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /*
    fixed = parallax
    scroll = normal
  */
  background-attachment: fixed;
  min-height: 100vh;
  max-width: 100vw;

}

.section {
  text-align: center;
  padding: 40px 0px 32px 0px;
}

.section-light {
  /* background-color:#f4f4f4; */
  /* color:#666; */
  max-width: 100vw;
  overflow: hidden;
}

.section-light .my-header {
  background-image: -webkit-linear-gradient(20deg, #e7e7e7 50%, rgba(0, 0, 0, 0) 0%);
}

.section-light2 {
  /* background-color:#f4f4f4; */
  /* color:#666; */
  max-width: 100vw;
  overflow: hidden;
}

.section-light2 .my-header {
  background-image: -webkit-linear-gradient(20deg, rgba(0, 0, 0, 0) 50%, #e7e7e7 0%);
}

.section-light-about {
  /* background-color:#f4f4f4; */
  /* color:#666; */
  background-image: -webkit-linear-gradient(20deg, rgba(0, 0, 0, 0) 50%, #e7e7e7 0%);
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 50px 0px 3px 0px;
}

.section-dark {
  background-color: #282e34;
  color: #ddd;
}

.ptext {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  color: #000;
  font-size: 1em;
  letter-spacing: 8px;
  text-transform: uppercase;
}

.ptext-quote {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  color: #000;
  font-size: 1em;
}

.ptext .borders {
  background-color: #002a57;
  color: #fff;
  padding: 20px;
  font-weight: bold;
}

.borders:hover{
  background-color: white;
  color: black;
  padding: 30px;
  border-color: #002a57;
  border-width: 10px;
  border-style: solid;
}

.ptext .border.trans {
  background-color: transparent;
}

.ptext .border-rev {
  background-color: #fff;
  color: #002a57;
  padding: 20px;
  border: 2px solid #002a57;
  font-weight: bold;
}

.my-header {
  font-size: 3em;
  /* text-decoration: underline; */
  text-transform: uppercase;
  /* border: 40px solid black; */
  /* width: 50%;
  margin-left: 25%; */
  /* float: right; */
  /* width: 100vw; */
  /* height: 10.8vh; */
  line-height: 8vh;
  /* border-width: 53px; */
  padding: 10px;
  font-weight: 900;
  /* letter-spacing: 4PX; */
  letter-spacing: 2px;
  /* padding: 2vh 0vh 3vh 0vh; */
  font-size: 2em;
}

.quote-container {
  font-family: 'Satisfy', 'Dancing Script', serif;
  font-size: 2.7vw;
  color: #303633;
  font-weight: 600;
  line-height: 80px;
  width: 80%;
  border-radius: 25px;
  /* text-indent: 105px; */
  margin-left: 10%;
  background-color: white;
  height: 166px;
  margin-top: -83px;
  position: relative;
  text-transform: none !important;
}

.quote-container:before {
  content: '\201C';
  font-family: serif;
  font-style: normal;
  font-weight: 900;
  position: absolute;
  font-size: 175px;
  top: -11px;
  left: -105px;
  padding-left: 25px;
  color: white;
}

@media(max-width:320px) {
  .border-rev {
    font-size: 8px;
  }
}
@media(min-width:769px) and (max-width:1024px){
  .pimg , .pimgProject {
    background-attachment: scroll;
  }
}
@media(max-width:1366px) and (max-height:905px){
  .pimg , .pimgProject {
    background-attachment: scroll;
  }
}
@media(max-width:768px) {
  .pimg , .pimgProject {
    background-attachment: scroll;
  }
  .ptext2 {
    margin-top: 40px;
  }
  .ptext {
    font-size: 2.9vw;
    letter-spacing: 2.6px
  }
  .section {
    text-align: center;
    padding: 15px 0px 6px 0px;
  }
  #whatWeDo {
    padding-bottom: 23px !important;
  }
  .my-header {
    font-size: 1.5em;
    text-transform: uppercase;
    /* width: 100vw; */
    /* width: 90%;
    margin-left: 0%; */
    /* height: 7.5vh; */
    line-height: 5vh;
    font-weight: 900;
    letter-spacing: 1px;
  }
  .pimg .quote-container {
    font-family: 'Satisfy', 'Dancing Script', serif;
    font-size: 7vw;
    color: #303633;
    font-weight: 600;
    line-height: 65px;
    width: 80%;
    border-radius: 25px;
    /* text-indent: 105px; */
    margin-left: 10%;
    background-color: white;
    height: 250px;
    margin-top: -125px;
    position: relative;
    text-transform: none !important;
  }
  .pimg3 .quote-container {
    font-family: 'Satisfy', 'Dancing Script', serif;
    font-size: 6.5vw;
    color: #303633;
    font-weight: 600;
    line-height: 65px;
    width: 80%;
    border-radius: 25px;
    /* text-indent: 105px; */
    margin-left: 10%;
    background-color: white;
    height: 320px !important;
    margin-top: -160px;
    position: relative;
    text-transform: none !important;
  }
  .quote-container:before {
    content: '\201C';
    font-family: serif;
    font-style: normal;
    font-weight: 900;
    position: absolute;
    font-size: 120px;
    top: -30px;
    left: -40px;
    padding-left: 25px;
    color: white;
  }
  .cardsCont {
    font-weight: 500;
    /* font-size: 1.5vw; */
    display: flex;
    justify-content: center;
    /* text-align: justify; */
    font-family: 'Poppins-Light';
  }
  .homeContainer {
    padding: 1vh;
  }
  .leftColMain {
    padding: 1vh !important;
  }
  .serviceImageCol{
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .serviceImageColSecondRow{
    padding-top: 0px !important;
  }
}

@media(max-width:350px) {
  .cardsCont {
    font-weight: 500;
    display: flex;
    justify-content: center;
    /* text-align: justify; */
    font-family: 'Poppins-Light';
  }
  .pimg .quote-container {
    font-family: 'Satisfy', 'Dancing Script', serif;
    font-size: 7vw;
    color: #303633;
    font-weight: 600;
    line-height: 65px;
    width: 90%;
    border-radius: 25px;
    /* text-indent: 105px; */
    margin-left: 5%;
    background-color: white;
    height: 250px;
    margin-top: -125px;
    position: relative;
    text-transform: none !important;
  }
  .pimg3 .quote-container {
    font-family: 'Satisfy', 'Dancing Script', serif;
    font-size: 6.5vw;
    color: #303633;
    font-weight: 600;
    line-height: 65px;
    width: 90%;
    border-radius: 25px;
    /* text-indent: 105px; */
    margin-left: 5%;
    background-color: white;
    height: 320px;
    margin-top: -160px;
    position: relative;
    text-transform: none !important;
  }
  .quote-container:before {
    content: '\201C';
    font-family: serif;
    font-style: normal;
    font-weight: 900;
    position: absolute;
    font-size: 120px;
    top: -30px;
    left: -40px;
    padding-left: 25px;
    color: white;
  }
  .Logo {
    margin-right: 5px;
  }
  .homeContainer {
    padding: 1vh;
  }
  .leftColMain {
    /* padding: 1vh !important; */
  }
  .serviceImageCol{
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .serviceImageColSecondRow{
    padding-top: 0px !important;
  }
}

.leftColMain {
  /* background-color: green; */
  text-align: center;
  /* padding: 6vh; */
}

.ourMotoClass {
  /* background-color: green; */
  text-align: center;
  padding-top: 10vh;
  /* padding: 6vh; */
}

.ourMotoClassRow {
  text-align: center;
}



.homeContainer {
  margin-top: 3vh;
  margin-bottom: 3vh;
  padding: 3vh;
  max-width: 100vw;
  overflow: hidden;
}

/* big screen */

.cardsCont {
  font-weight: 500;
  /* font-size: 1.5vw; */
  /* text-align: justify; */
  display: flex;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
  font-size: 1em;
  line-height: 40px;
  /* min-height: 26vh; */
  border-radius: 25px !important;
  letter-spacing: 1px;
  background-color: #fcde58 !important;
  border-width: 3px;
  /* background-color: greenyellow !important; */
  -webkit-font-smoothing: antialiased;
  /* transform: skewX(17deg); */
}

.cardsCont:hover{
  background-color: white !important;
  /* color: black; */
  border-color: #fcde58;
  border-width: 3px;
  /* padding: 30px; */
  border-style: solid;
}

.cardsCont>.card-body {
  /* transform: skewX(-30deg) !important; */
}

.lastHeading {
  /* min-height: 80vh !important; */
}

.serviceImageCol{
  padding-left: 40px;
  padding-right: 40px;
}

.serviceImageColSecondRow{
  padding-top: 40px;
}

.snip1321 figcaption{
  transform: translateY(-50%);
  transition: none;
  visibility: hidden;;
}

.snip1321:hover figcaption, .snip1321.hover figcaption {
  transition-delay: unset !important;
  visibility: visible;
  -webkit-animation: fadein 2s; 
       animation: fadein 2s;
}






.clientsImg {
  /* vertical-align:middle; */
  /* height: 100%; */
  /* background-color: white; */
  /* height: 200px; */
  /* min-height: 200px; */
  /* max-height: 200px; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* padding: 5px; */
  /* overflow: hidden; */
}

.slideInRight{
  max-width: 100vw;
  overflow: hidden;
}


.ourServiceMainRow{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    /* height: 100vh; */
    padding: 5vh 0vh 5vh 0vh;
}
.ourClientMainRow{
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /* height: 60vh; */
  padding: 5vh 0vh 5vh 0vh;
}

.pimg3{
  min-height: 0vh !important;
}

figcaption > h4{
  font-size: 1.5em;
}

figcaption > h2{
  font-size: 1em;
}

.ourClientsCol{
  display: flex;
  justify-content: center;
}

.svg-inline--fa {
  vertical-align: -0.450em !important;
}


 .borders{
  /* antimated trantitions for all elements */
  /* For Safari 3.1 to 6.0 */
  transition: 0.5s;
}

.svg-inline--fa.fa-w-10 {
  margin-top: 15%;
}
.Content {
    margin-top: 72px;
}

.Toolbar {
    height: 56px;
    width: 100vw;
    max-width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #e7e7e7;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 90;
    background-image: -webkit-linear-gradient(20deg, #e7e7e7 50%, rgba(0, 0, 0, 0) 0%);
    BACKGROUND-COLOR: WHITE;
    transition: top 0.4s;
}

.Toolbar-hidden {
    top: -56px !important;
}

.Toolbar nav {
    height: 100%;
}

.Logo {
    position: absolute;
    right: 0;
    margin-right: 20px;
    height: 50px;
}
.companyNameMenu{
    position: absolute;
    right: 0;
    margin-right: 100px;
    /* height: 60px; */
    color: #0C1482;
    text-align: center;
    font-size: 1.75rem;
    font-family: 'Poppins', sans-serif;
}

.logoutButton{
    position: absolute;
right: 0;
margin-right: 100px;
}

@media (max-width: 1024px) {
    .DesktopOnly {
        display: none;
    }
    .companyNameMenu{
        display: none;
    }
}



.Logo {
    /* background-color: white; */
    /* padding: 8px; */
    /* height: 100%; */
    box-sizing: border-box;
    border-radius: 5px;
}

.Logo img {
    height: 100%;
}
.NavigationItems {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 100%;
    letter-spacing: 1px;
}

@media (min-width: 1025px) {
    .NavigationItems {
        flex-flow: row;
    }
}
.NavigationItem {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
}

.NavigationItem a {
    color: #002a57;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
    background-color: #e7e7e7;
    border-bottom: 4px solid #002a57;
    color: #002a57;
    font-weight: bold;

}

@media (min-width: 1025px) {
    .NavigationItem {
        margin: 0;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
    }
    
    .NavigationItem a {
        color: #002a57;
        height: 100%;
        padding: 16px 10px;
        border-bottom: 4px solid transparent;
    }
    
    .NavigationItem a:hover,
    .NavigationItem a:active,
    .NavigationItem a.active {
        background-color: #e7e7e7;
        border-bottom: 4px solid #002a57;
        color: #002a57;
    }
}
.DrawerToggle {
    width: 40px;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
    cursor: pointer;
}

.DrawerToggle div {
    width: 90%;
    height: 3px;
    background-color: black;
}

@media (min-width: 1025px) {
    .DrawerToggle {
        display: none;
    }
}
.SideDrawer {
    position: fixed;
    width: 280px;
    max-width: 70%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: #e7e7e7;
    padding: 32px 16px;
    box-sizing: border-box;
  }

.initial-close{
    display: none;
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes open-sidebar {
    from {
        left: -100%;
    }      
    to{
        left: 0%;      
    }
}

/* Standard syntax */
@keyframes open-sidebar {
    from {
        left: -100%;
    }      
    to{
        left: 0%;   
    }
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes close-sidebar {
    from {
        left: 0px;
    }      
    to{
        left: 0px;    
    }
}

/* Standard syntax */
@keyframes close-sidebar {
    from {
        left: 0px;
    }      
    to{
        left: 0px;     
    }
}

@media (min-width: 1025px) {
    .SideDrawer {
        display: none;
    }
}

.Open {
    transform: translateX(0);
    -webkit-animation-name: open-sidebar; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 0.5s; /* Safari 4.0 - 8.0 */
    animation-name: open-sidebar;
    animation-duration: 0.5s;    
}

.Close {
    transform: translateX(-100%);
    -webkit-animation-name: close-sidebar; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 0.5s; /* Safari 4.0 - 8.0 */
    animation-name: close-sidebar;
    animation-duration: 0.5s;     
}

/* .Logo {
    height: 11%;
    margin-bottom: 32px;
} */
.Backdrop{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.9);
    overflow: hidden;
    visibility: hidden;
}

.initialBackDropClose{
    visibility: hidden;;
}

.backdropOpen{
    visibility: visible;
    -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
            animation: fadein 0.5s;
}

.backdropClose{
-webkit-animation: fadeout 0.5s; 
     animation: fadeout 0.5s;
     visibility: hidden;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */

/* Opera < 12.1 */


@keyframes fadeout {
    from { opacity: 1;  visibility: visible;}
    to   { opacity: 0;  visibility: hidden;}
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
    from { opacity: 1;  visibility: visible;}
    to   { opacity: 0;  visibility: hidden;}
}

/* Internet Explorer */

/* Opera < 12.1 */


@keyframes fadeout {
    from { opacity: 1;  visibility: visible;}
    to   { opacity: 0;  visibility: hidden;}
}
.scrollTop {
    height: 20px;
    text-align: right;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 99999;
    cursor: pointer;  
  }
  
  
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }
  
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }

  .scrollTop span {
    width: 50px;
    height: 50px;
    display: block;
    transition: 1s;
    background: #002a57;
    border-radius: 40px;
    color: #fff;
    font-size: 28px;
    text-align: center;
    border: 2px solid #fff;
}
.scrollTop span:hover {
    background: #f1242b;
    color: #fff;
}
.FooterMain {
    background-color: #002a57;
    color: #ffffff;
    padding-top: 70px;
    /* padding-bottom: 100px; */
}

.certFooterDiv {
    /* padding-top: 50px; */
}

.certTittle {
    font-size: 26px;
    /* margin-top: 30px; */
    /* top: 10px; */
    /* position: relative; */
    font-weight: bold;
    color: white;
    /* margin-left: 40px; */
    /* margin-bottom: 20px; */
    display: inline-block;
}

.footerCertImg {
    background-color: white;
    /* height: 120px; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    /* overflow: hidden; */
    /* max-width:20%; */
}

.footerCertImgDiv {
    /* padding: 40px; */
    padding-top: 20px;
    padding-bottom: 20px;
    /* padding-right: 1vw; */
}

.copyWrite {
    text-align: center;
    padding: 5px;
    
    color: white;
    font-size: 0.7rem;
}

.copyWriteAdmin {
    text-align: center;
    padding: 15px;
    background-color: white;
    color: black;
}

.footerRight {
    /* background-color: violet; */
    float: right;
}

.footerLeft {
    /* background-color: teal; */
    float: left;
}

.addressText {
    padding-left: 15px;
}

/* .backtop {
    text-align: right;
    padding: 20px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 99999;
    display: block;
}
.backtop a {
    width: 40px;
    height: 40px;
    display: block;
    -webkit-transition: 1s;
    -moz-transition: 1s;
    transition: 1s;
    background: #005baa;
    border-radius: 40px;
    color: #fff;
    font-size: 28px;
    text-align: center;
}
.backtop a:hover {
    background: #f1242b;
    color: #fff;
} */

.FooterLogo {
    padding: 5px;
}

.footerName {
    align-items: right;
    font-size: 1.5em;
}

.footerIconDiv {
    /* background-color: yellow; */
    overflow: hidden;
}

.footerIconDiv1 {
    /* background-color: red; */
}

.footerCompanyName{
    font-size: 1.75rem !important;
}
.footerQoute {
    color: greenyellow;
    font-size: 1.25rem;
}

.certRowFooter {
    padding: 10px;
}

/* ipad and phone */
@media(max-width:992px) {
    
    
}


/* phone only */
@media(max-width:576px) {
    .footerCompanyName{
        font-size: 1em !important;
    }
    .footerQoute{
        font-size: 0.80rem !important;
    }
    .footerCDHeading{
        font-size: 1.6rem !important;
        padding-top: 5vw;
    }
    .footerCertHeader{
        font-size: 1.6rem !important;
    }
}

.brdr {
    width: 100%;
    height: 1px;
    background: white;
    margin: 20px 0;
    color: #ffffff;
}

.socalIcons{
   padding-left: 10px;
   
}

.FooterMain p{
    font-size: 13px;
    line-height: 20px;
}

.FooterMain ul {
    list-style: none;
    padding: 0px 0px 0px 0px;
}
.FooterMain li a,.FooterMain a  {
    font-size: 13px;
    transition: .5s all ease;
    text-decoration: none;
    
}

.ftrLink {
    color: white;
    font-size: 15px;
}
.ftrMainlink {
    font-size: 18px !important;
    text-transform: uppercase;
}

.redtest{
    border-style: solid;
  border-color: coral;
}

.ftrLinkSer{
    color: white;
    font-size: 15px;
}

.ftrLinkSer:hover{
    color: #007bff !important;
    cursor: pointer;
}

.snip1321 {
  perspective: 50em;
  box-sizing: padding-box;
  transition: all 0.2s ease-out;
  /* overflow-x: scroll; */
  margin: 10px 10px 10px 10px !important;
  
}

/* .snip1321 Img {
  max-width: 100%;
  vertical-align: top;
} */

.snip1321 figcaption {
  top: 50%;
  left: 20px;
  right: 20px;
  position: absolute;
  opacity: 0;
  z-index: 1;
}

/* .snip1321 h2,
.snip1321 h4 {
  margin: 0;
}
.snip1321 h2 {
  font-weight: 600;
}
.snip1321 h4 {
  font-weight: 400;
  text-transform: uppercase;
}
.snip1321 i {
  font-size: 32px;
} */

.snip1321:after {
  background-color: #ffffff;
  position: absolute;
  content: "";
  display: block;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  transition: all 0.4s ease-in-out;
  transform: rotateX(-90deg);
  transform-origin: 50% 50%;
  opacity: 0;
}

.snip1321 a {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: 1;
}

.snip1321:hover figcaption, .snip1321.hover figcaption {
  transform: translateY(-50%);
  opacity: 1;
  transition-delay: 0.2s;
}

.snip1321:hover:after, .snip1321.hover:after {
  transform: rotateX(0);
  opacity: 1;
}




/* @import url(https://fonts.googleapis.com/css?family=Raleway:400,200,300,800); */
.snip0015 {


  /* font-family: 'Raleway', Arial, sans-serif; */
  justify-content: center;
  color: #fff;
  position: relative;
  overflow: hidden;
  margin: 10px;
  min-height: 200px; 
  max-height: 200px;
  /* height: 200px; */
  width: 250px;
  background: #000000;
  text-align: center;
  
}
.snip0015 * {
  box-sizing: border-box;
  
}
.snip0015 img {
  
  opacity: 1;
  width: 100%;
  transition: opacity 0.35s;
  
}
.snip0015 figcaption {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 3em 3em;
  width: 100%;
  height: 100%;
 
}
.snip0015 figcaption::before {
  
  position: absolute;
  top: 10%;
  right: 10px;
  bottom: 10%;
  left: 10px;
  border-top: 1px solid rgb(14, 14, 14);
  border-bottom: 1px solid rgb(14, 14, 14);
  content: '';
  opacity: 0;
  /* background-color: #ffffff; */
  transition: all 0.4s;
  transition-delay: 0.6s;

}
.snip0015 h2,
.snip0015 h5,
.snip0015 p {
  
  margin: 0 0 5px;
  opacity: 0;
  transition: opacity 0.35s,transform 0.35s;
}

.snip0015 h2 {
  
  word-spacing: -0.15em;
  font-weight: 300;
  text-transform: uppercase;
  transform: translate3d(0%, 50%, 0);
  transition-delay: 0.3s;
}
.snip0015 h2 span {
  font-weight: 800;
}
.snip0015 p {
  font-weight: 200	;
  transition-delay: 0s;
}
.snip0015 a {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  /* color: #ffffff; */
}
.snip0015:hover img {
  opacity: 0.35;
 
  
}

.snip0015:hover figcaption h5 {
  opacity: 1;
  transform: translate3d(0%, 0%, 0);
  transition-delay: 0.3s;
  /* color: black; */
}
.snip0015:hover figcaption h2 {
  opacity: 1;
  transform: translate3d(0%, 0%, 0);
  transition-delay: 0.3s;
  /* color: black; */
}
.snip0015:hover figcaption p {
  opacity: 0.9;
  transition-delay: 0.6s;
}
.snip0015:hover figcaption::before {
  background: rgba(255, 255, 255, 0);
  top: 30px;
  bottom: 30px;
  opacity: 1;
  transition-delay: 0s;
  /* color: red; */
  /* background-color: red; */
}
.snip1543 {
  background-color: #fff;
  color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  margin: 10px;
  max-width: 200px;
  min-width: 250px;
  overflow: hidden;
  position: relative;
  text-align: left;
  width: 100%;
  transform: translateZ(0);
}

.snip1543 *,
.snip1543 *:before,
.snip1543 *:after {
  box-sizing: border-box;
  transition: all 0.45s ease;
}

.snip1543 img {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  max-width: 100%;
  vertical-align: top;
}

.snip1543:before,
.snip1543:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: '';
  background-color: #002a57;
  opacity: 0.5;
  transition: all 0.45s ease;
}

.snip1543:before {
  transform: skew(30deg) translateX(-80%);
}

.snip1543:after {
  transform: skew(-30deg) translateX(-70%);
}

.snip1543 figcaption {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
  bottom: 0;
  padding: 25px 40% 25px 20px;
}

.snip1543 figcaption:before,
.snip1543 figcaption:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #002a57;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
  content: '';
  opacity: 0.5;
  z-index: -1;
}

.snip1543 figcaption:before {
  transform: skew(30deg) translateX(-100%);
}

.snip1543 figcaption:after {
  transform: skew(-30deg) translateX(-90%);
}

.snip1543 h3,
.snip1543 p {
  margin: 0;
  opacity: 0;
  letter-spacing: 1px;
}

.snip1543 h3 {
  font-family: 'Teko', sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 1em;
  text-transform: uppercase;
}

.snip1543 p {
  font-size: 0.9em;
}

.snip1543 a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.snip1543:hover h3,
.snip1543.hover h3,
.snip1543:hover p,
.snip1543.hover p {
  transform: translateY(0);
  opacity: 0.9;
  transition-delay: 0.2s;
}

.snip1543:hover:before,
.snip1543.hover:before {
  transform: skew(30deg) translateX(-20%);
  transition-delay: 0.05s;
}

.snip1543:hover:after,
.snip1543.hover:after {
  transform: skew(-30deg) translateX(-10%);
}

.snip1543:hover figcaption:before,
.snip1543.hover figcaption:before {
  transform: skew(30deg) translateX(-40%);
  transition-delay: 0.15s;
}

.snip1543:hover figcaption:after,
.snip1543.hover figcaption:after {
  transform: skew(-30deg) translateX(-30%);
  transition-delay: 0.1s;
}


/* Included color classes.. 
.red 
.blue 
.yellow 
*/
.snip1104 {
font-family: 'Raleway', Arial, sans-serif;
position: relative;
overflow: hidden;
margin: 10px;
min-width: 320px;
max-width: 310px;
max-height: 220px;
/* width: 100%; */
background: #000000;
color: #ffffff;
text-align: center;
box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

.snip1104 * {
box-sizing: border-box;
transition: all 0.4s ease-in-out;
}

.snip1104 img {
max-width: 100%;
position: relative;
opacity: 0.4;
}

.snip1104 figcaption {
position: absolute;
top: 0;
left: 0;
bottom: 0;
right: 0;
}

.snip1104 h2 {
position: absolute;
left: 40px;
right: 40px;
display: inline-block;
background: #000000;
transform: skew(-10deg) rotate(-10deg) translate(0, -50%);
padding: 12px 5px;
margin: 0;
top: 50%;
text-transform: uppercase;
font-weight: 400;
}

.snip1104 h2 span {
font-weight: 800;
}

.snip1104:before {
height: 100%;
width: 100%;
top: 0;
left: 0;
content: '';
background: #ffffff;
position: absolute;
transition: all 0.3s ease-in-out;
transform: rotate(110deg) translateY(-50%);
}

.snip1104 a {
left: 0;
right: 0;
top: 0;
bottom: 0;
position: absolute;
z-index: 1;
}

.snip1104.blue {
background: #123851;
}

.snip1104.blue h2 {
background: #0a212f;
}

.snip1104.red {
background: #581a14;
}

.snip1104.red h2 {
background: #36100c;
}

.snip1104.yellow {
background: #7f5006;
}

.snip1104.yellow h2 {
background: #583804;
}

.snip1104:hover img,
.snip1104.hover img {
opacity: 1;
transform: scale(1.1);
}

.snip1104:hover h2,
.snip1104.hover h2 {
transform: skew(-10deg) rotate(-10deg) translate(-150%, -50%);
}

.snip1104:hover:before,
.snip1104.hover:before {
transform: rotate(110deg) translateY(-150%);
}
.snip1401 {
font-family: 'Playfair Display', Arial, sans-serif;
position: relative;
overflow: hidden;
margin: 10px;
min-width: 230px;
max-width: 315px;
max-height: 220px;
width: 100%;
color: #000000;
text-align: right;
font-size: 16px;
background-color: #000000;
}
.snip1401 * {
box-sizing: border-box;
transition: all 0.35s ease;
}
.snip1401 img {
max-width: 100%;
-webkit-backface-visibility: hidden;
        backface-visibility: hidden;
}
.snip1401 figcaption {
position: absolute;
top: 0;
bottom: 0;
right: 0;
z-index: 1;
opacity: 1;
padding: 30px 0 30px 10px;
background-color: #ffffff;
width: 40%;
transform: translateX(150%);
}
.snip1401 figcaption:before {
position: absolute;
top: 50%;
transform: translateY(-50%);
right: 100%;
content: '';
width: 0;
height: 0;
border-style: solid;
border-width: 120px 120px 120px 0;
border-color: transparent #ffffff transparent transparent;
}
.snip1401:after {
position: absolute;
bottom: 50%;
right: 40%;
content: '';
width: 0;
height: 0;
border-style: solid;
border-width: 120px 120px 0 120px;
border-color: rgba(255, 255, 255, 0.5) transparent transparent transparent;
transform: translateY(-50%);
transition: all 0.35s ease;
}
.snip1401 h3,
.snip1401 p {
line-height: 1.5em;
transform: translateX(-30px);
margin: 0;
}
.snip1401 h3 {
margin: 0 0 5px;
line-height: 1.1em;
font-weight: 900;
font-size: 1.4em;
opacity: 0.75;
}
.snip1401 p {
font-size: 0.8em;
}
.snip1401 i {
position: absolute;
bottom: 0;
left: 0;
padding: 20px 30px;
font-size: 44px;
color: #ffffff;
opacity: 0;
}
.snip1401 a {
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
z-index: 1;
}
.snip1401:hover img,
.snip1401.hover img {
zoom: 1;
filter: alpha(opacity=50);
-webkit-opacity: 0.5;
opacity: 0.5;
}
.snip1401:hover:after,
.snip1401.hover:after,
.snip1401:hover figcaption,
.snip1401.hover figcaption,
.snip1401:hover i,
.snip1401.hover i {
transform: translateX(0);
opacity: 1;
}
.snip1584 {
font-family: 'Raleway', sans-serif;
position: relative;
display: inline-block;
overflow: hidden;
margin: 10px;
min-width: 230px;
max-width: 315px;
width: 100%;
color: #ffffff;
font-size: 16px;
text-align: left;
}
.snip1584 * {
box-sizing: border-box;
transition: all 0.25s ease;
}
.snip1584:before {
position: absolute;
top: 10px;
bottom: 10px;
left: 10px;
right: 10px;
top: 100%;
content: '';
background-color: rgba(51, 51, 51, 0.9);
transition: all 0.25s ease;
transition-delay: 0.25s;
}
.snip1584 img {
vertical-align: top;
max-width: 100%;
-webkit-backface-visibility: hidden;
        backface-visibility: hidden;
}
.snip1584 figcaption {
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
z-index: 1;
align-items: center;
display: flex;
flex-direction: column;
justify-content: center;
}
.snip1584 h3,
.snip1584 h5 {
margin: 0;
opacity: 0;
letter-spacing: 1px;
}
.snip1584 h3 {
transform: translateY(-100%);
text-transform: uppercase;
font-weight: 400;
transition-delay: 0.05s;
margin-bottom: 5px;
}
.snip1584 h5 {
font-weight: normal;
background-color: #ae895d;
padding: 3px 10px;
transform: translateY(-100%);
transition-delay: 0s;
}
.snip1584 a {
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
z-index: 1;
}
.snip1584:hover:before,
.snip1584.hover:before {
top: 10px;
transition-delay: 0s;
}
.snip1584:hover h3,
.snip1584.hover h3,
.snip1584:hover h5,
.snip1584.hover h5 {
transform: translateY(0);
opacity: 1;
}
.snip1584:hover h3,
.snip1584.hover h3 {
transition-delay: 0.3s;
}
.snip1584:hover h5,
.snip1584.hover h5 {
transition-delay: 0.2s;
}
.snip1573 {
background-color:white;
/* display: inline-block; */
font-family: 'Open Sans', sans-serif;
font-size: 16px;
margin: 10px;
/* max-width: 250px; */
/* min-width: 250px; */
max-height: 25vh;
min-height: 25vh;
overflow: hidden;
position: relative;
text-align: center;
  /* align-items: center; */
justify-content: center;
/* width: 100%; */
}
/* @media(min-width:576px) {
.snip1573 {
max-height: 20vh;
min-height: 20vh;
}
} */

@media (min-width: 992px){
  .snip1573 {
      max-height: 10vw;
      min-height: 10vw;
      min-width: 10vw;
      max-width: 10vw;
  }
}
@media (max-width:556px) {
  .snip1573 {
  max-height: 35vw;
  min-height: 35vw;
  min-width: 35vw;
  max-width: 35vw;
  }
}
@media( min-width:556px) and (max-width:992px){
  .snip1573 {
  max-height: 25vw;
  min-height: 25vw;
  min-width: 25vw;
  max-width: 25vw;
  }
}
.snip1573 * {
box-sizing: border-box;
transition: all 0.35s ease;
}

.snip1573:before,
.snip1573:after {
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
transition: all 0.35s ease;
background-color:  #002a57;
border-left: 3px solid #fff;
border-right: 3px solid #fff;
content: '';
opacity: 0.9;
z-index: 1;
}

.snip1573:before {
transform: skew(45deg) translateX(-155%);
}

.snip1573:after {
transform: skew(45deg) translateX(155%);
}

.snip1573 img {
-webkit-backface-visibility: hidden;
        backface-visibility: hidden;
max-width: 100%;
vertical-align: top;
}

.snip1573 figcaption {
top: 50%;
left: 50%;
position: absolute;
z-index: 2;
transform: translate(-50%, -50%) scale(0.5);
opacity: 0;
box-shadow: 0 0 10px #000000;
}

.snip1573 h3 {
background-color:  #002a57;
border: 2px solid #fff;
color: #fff;
font-size: 0.8em;
font-weight: 600;
letter-spacing: 1px;
margin: 0;
padding: 5px 10px;
text-transform: uppercase;
}

.snip1573 a {
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
z-index: 3;
}

.snip1573:hover > img,
.snip1573.hover > img {
opacity: 0.5;
}

.snip1573:hover:before,
.snip1573.hover:before {
transform: skew(45deg) translateX(-55%);
}

.snip1573:hover:after,
.snip1573.hover:after {
transform: skew(45deg) translateX(55%);
}

.snip1573:hover figcaption,
.snip1573.hover figcaption {
transform: translate(-50%, -50%) scale(1);
opacity: 1;
}


.biZcert{
    /* min-height: 80vh !important; */
  }
  
  .bizDiv{
    text-align: center;
  }
  .bizDiv img{
    width: 40vw;
    height: 100vh;
    border: 2px solid black;
  }
  @media(max-width:768px) {
    .bizDiv img{
      width: 80vw;
      height: 60vh;
    }
    .biZcert{
      min-height: 60vh !important;
    }
  }

.no-body-scroll{
  height: 100%;
  width: 100%;
  overflow: hidden !important;
  position: fixed !important;
}

/* body{
  color: #002a57 !important;
} */

.AboutMain{
  height:100vh;
  margin:0;
  font-size:16px;
  font-family:"Lato", sans-serif;
  font-weight:400;
  line-height:1.8em;
  color:#002a57;
  /* overflow: scroll;
  position: fixed; */
}

.ptext-aboutMessage{
  top: 70%;
  width: 88%;
  margin-left: 6%;
  text-align: justify;
  font-size: 1.3em;
  /* font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif; */
  font-family: 'Poppins', sans-serif;
  /* font-weight: bold; */
  line-height: 45px;
  background: white;
  border-radius: 25px;
  color: 002a57;
  padding: 30px 30px 30px 30px;
}

.about-heading{
  position:absolute;
  top:50%;
  width: 100%;
  text-align:center;
  color:#000;
  font-size:2vw;
  letter-spacing:8px;
  text-transform:uppercase;
}

.about-heading .borders{
  background-color:#111;
  color:#fff;
  padding:20px;
}

.my-header-main{
    /* text-decoration: underline; */
    text-transform: uppercase;
    /* border: 40px solid black; */
    width: 80%;
    margin-left: 10%;
    /* float: right; */
    height: 20px;
    line-height: 0px;
    font-weight: bold;
    letter-spacing: 2px;
    margin-top: -20px;
    /* padding: 2vh 0vh 3vh 0vh; */
    font-size: 2rem !important;
}

.my-header-sub{
  /* text-decoration: underline; */
  text-transform: uppercase;
  /* border: 40px solid black; */
  width: 80%;
  margin-left: 10%;
  /* float: right; */
  height: 20px;
  line-height: 0px;
  font-weight: bold;
  letter-spacing: 2px;
  margin-top: -20px;
  /* padding: 2vh 0vh 3vh 0vh; */
  font-size: 2rem !important;
}

@media(max-width:768px){
  .my-header-main{
    /* font-size: 6vw; */
    /* text-decoration: underline; */
    text-transform: uppercase;
    /* border: 40px solid black; */
    width: 80%;
    margin-left: 10%;
    /* float: right; */
    height: 7vh;
    line-height: 5vh;
    /* border-width: 53px; */
    padding: 10px;
    font-weight: 900;
    letter-spacing: 4PX;
    font-size: 1.4em !important;
  }
  
  .my-header-sub{
    font-size: 6vw;
    /* text-decoration: underline; */
    text-transform: uppercase;
    /* border: 40px solid black; */
    width: 80%;
    margin-left: 10%;
    /* float: right; */
    height: 7vh;
    line-height: 5vh;
    /* border-width: 53px; */
    padding: 10px;
    font-weight: 900;
    letter-spacing: 4PX;
    font-size: 1.4em !important;
  }

  .ptext-aboutMessage{
    /* font-size: 3.5vw; */
    padding:6px;
    font-size: 1em;
    line-height: 30px;
    /* text-align: justify; */
    
  }  

  .team{
    height: auto !important;
  }
}

@media(max-width:568px){
  .my-header-main{
    /* font-size: 6vw; */
    /* text-decoration: underline; */
    text-transform: uppercase;
    /* border: 40px solid black; */
    width: 80%;
    margin-left: 10%;
    /* float: right; */
    height: 7vh;
    line-height: 7vh;
    /* border-width: 53px; */
    padding: 10px;
    font-weight: 900;
    letter-spacing: 4PX;
    font-size: 1.4rem !important;
  }
  
  .my-header-sub{
    font-size: 6vw;
    /* text-decoration: underline; */
    text-transform: uppercase;
    /* border: 40px solid black; */
    width: 80%;
    margin-left: 10%;
    /* float: right; */
    height: 7vh;
    line-height: 5vh;
    /* border-width: 53px; */
    padding: 10px;
    font-weight: 900;
    letter-spacing: 4PX;
    font-size: 1.4rem !important;
  }

  .ptext-aboutMessage{
    /* font-size: 3.5vw; */
    padding:6px;
    font-size: 1em;
    line-height: 30px;
  }  

  .team{
    height: auto !important;
  }
}

.leftCard{
  display: inline-block;
  background: tomato;
  padding: 5px;
  width: 500px;
  height: 500px;
  margin-top: 10px;
  margin: 50px;
  
}

.team{
  /* height: 100vh; */
    /* width: 100vw; */
    /* bottom: 0; */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 30px; */
    text-align: center;
    
}


.teamSay{
  /* height: 100vh; */
    /* width: 100vw; */
    /* bottom: 0; */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}



.otherSpec{
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}


/* can remove later */
.AboutBase {
  /* margin-top: 100px; */
  margin-bottom: 100px;
  margin-left: 5% ;
  margin-right: 5% ;
  font-size:16px;
  /* font-family:"Lato", sans-serif; */
  font-family: 'Poppins', sans-serif;
  font-weight:400;
  line-height:1.5em;
  color:#002a57
  ;
}
/* @import url('compass/css3'); */

.flex-container {
  padding: 10px;
  margin: 0;
  list-style: none;
  display: flex;
  
  
  justify-content: space-around;
}

.flex-item {
  background: tomato;
  padding: 5px;
  width: 200px;
  height: 150px;
  margin-top: 10px;
  
  
  line-height: 150px;
  color: white;
  font-weight: bold;
  font-size: 3em;
  text-align: center;
  }

  
.flex-item-q {
  background: blue;
  padding: 5px;
  width: 600px;
  height: 150px;
  margin-top: 10px;
  
  
  line-height: 150px;
  color: white;
  font-weight: bold;
  font-size: 3em;
  text-align: center;
  }

 #whatWeDo{
   padding-bottom: 50px;
 }
*:not(input) {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.section-hidden{
    display: none;
}

.LearnMore {
    /* background-color: white; */
    outline: none;
    cursor: pointer;
    border: 4px solid #002a57;
    color: #002a57; 
    letter-spacing: 4px;  
    font-size: 0.7em;
    padding: 5px 20px;
    border-radius: 10px;
    margin-top: 2vh;
    margin-bottom: 1vh;
  }

  .learnMoreSubHeading{
    font-weight: bold;
    letter-spacing: 2px;
    padding: 2vh 0vh 2vh 0vh;
    font-size: 2rem !important;
  }
  

  .cardMain{
    position: absolute;
    width: 80%;
    margin-left: 10%;
    background:lightgray;
    top: 10%;
    padding: 2%;
  }

  .ptext-cardcontent{
    position: absolute;
    /* top: 20%; */
    width: 80%;
    /* margin-left: 10%; */
    text-align: center;
    font-size: 1.3em;
    /* font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif; */
    font-family: 'Poppins', sans-serif;
    /* font-weight: bold; */
    line-height: 40px;
    background-color: rgba(255,255,255,0.9);
    border-radius: 25px;
    color: #002a57;
    /* font-weight: 900; */
    
    padding: 30px 5px 30px 5px;
    max-height: 110vh;
    overflow: auto;
  }

.centreContent{
  height: 120vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.paraCss{
  text-align: justify;
  padding: 15px;
  text-indent: 4em;
  /* text-shadow: -1px 0#002a57, 0 1px white, 1px 0 white, 0 -1px white; */
}

  @media(max-width:768px){
    .ptext-cardcontent{
      font-size: 0.8em;
      line-height: 27px;
      padding: 1px 5px 17px 5px;
    }  
    .learnMoreSubHeading {
      font-size: 1.5em !important;
    }
  }
.Modal {
    z-index: 200;
    background-color: white;
    width: 70%;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    left: 15%;
    /* top: 30%; */
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    border-radius: 10px;
    max-height: 90vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.mainModal{
    z-index: 500;
    position: fixed;
    height: 100vh;
    width: 100vw;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: none;
}

@media (min-width: 768px) {
    .Modal {
        width: 70%;
        left: 15%;
    }
}
@media(max-width:769px){
    .Modal {
        width: 90vw;
        left: 5vw;
        margin-top: 23vw;
        max-height: 80vh;
        overflow-x: hidden;
        overflow-y: auto;
    }
}

@media(max-width:350px){
    .Modal {
        width: 90vw;
        left: 5vw;
        margin-top: 23vw;
        max-height: 80vh;
        overflow-x: hidden;
        overflow-y: auto;
    }
}

.reorderListItem{
    border: 1px solid #002a57;
}

.RearrangeProjectsHeading{
    /* background-color:#606918d1; */
    /* color: #fff; */
    /* border: 1px solid black; */
    background-image: -webkit-linear-gradient(20deg, #e7e7e7 50%, rgba(0, 0, 0, 0) 0%);
}

.reSortmodalCss{
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    line-height: 1.8em;
    color:  #002a57;
    
}
.LearnMoreLogo{
    align-items: center;
    height: 6vw;
    width: 8vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
  
}

.learnMoreHeading{
    text-align: center;
    font-weight: bold;
    letter-spacing: 2px;
    padding: 2vh 0vh 3vh 0vh;
    font-size: 2em;
    /* font-size: 3vw; */
    font-family: 'Poppins', sans-serif;
    /* font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif; */
}
.learnMoreContent{
    padding: 0px 50px 0px 50px;
    /* font-size: 1vw; */
    font-family: 'Poppins', sans-serif;
    /* font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif; */
}
.bizSafeLogo{
    margin-left: auto;
    height: 7vh;
    width: 14vw;
    float: right;
}
.closeBtn {
    background-color: white;
    outline: none;
    cursor: pointer;
    border: 4px solid #002a57;
    color: #002a57;   
    font-size: 1.2em;
    padding: 15px 30px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    letter-spacing: 2px;
    margin-top: 3vh;
  }
@media (max-width: 768px) {
    .learnMoreContent{
        padding: 0px 6px 0px 6px;
        font-size: 3vw;
        /* font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif; */
        font-family: 'Poppins', sans-serif;
    }
    .LearnMoreLogo{
        height: 18vw;
        width: 25vw;
    }
    .learnMoreHeading{
        font-size: 1.5em;
    }
    .bizSafeLogo{
        width: 30vw;
    }
    .closeBtn{
        padding: 0px 30px;
    }
}
button:focus {outline:0 !important;}
.projectMain {
  height:100vh;
  margin:0;
  font-size:16px;
  /* font-family:"Lato", sans-serif; */
  font-family: 'Poppins', sans-serif;
  font-weight:400;
  line-height:1.8em;
  color:#002a57 !important;
  }
  .moreProjectTable{
     padding-top: 10vh;
     padding-bottom: 10vh;
  }

  .myColheight{
    max-height: 80vh;
    min-height: 80vh;
  }

  .pcardPara2{
    /* font-weight: bold; */
     /* min-height: 70vh; */
     overflow: scroll;
    max-height: 75vh;
    /* min-height: 75vh; */
     
}

.pcardCont{
  overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    max-height: 80vh;
    min-height: 80vh;
}
 .pimgProject{
  position:relative;
  opacity:1;
  background-position:center;
  background-size:cover;
  background-repeat:no-repeat;
  /* max-height:70vh;
  min-height:70vh; */
  background-attachment:fixed;
  max-width: 100vw;
 }


 .carousel-control-next-icon, .carousel-control-prev-icon {
  
  width: 50px !important;
  height: 50px !important;
}

@media(min-width:769px) and (max-width:1024px){
  .pimg , .pimgProject {
    background-attachment: scroll;
  }
}
@media(max-width:1366px) and (max-height:905px){
  .pimg , .pimgProject {
    background-attachment: scroll;
  }
}
@media(max-width:768px) {
  .pimg , .pimgProject {
    background-attachment: scroll;
  }
}
.stepImage {
    background: white;
}

.pcard {
    /* overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center; */
    /* padding-top: 10vh; */
    /* padding-bottom: 10vh; */
    /* height: 100vh; */
}

.pcard2 {
    /* padding-top: 10vh;
     padding-bottom: 10vh; */
    /* min-height: 60vh;
     max-height: 60vh; */
}

.pcardPara {
    /* background:#e7e7e7 ; */
    min-height: 80vh;
    font-weight: bold;
}

.pimg-plain {
    background-color: rgba(0, 0, 0, 0.5);
}

.row {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.projectCardEditMainClass{
    /* padding-bottom: 20px; */
}

.tryCss {
    /* background-color:cyan; */
    border: black;
    border-style: groove;
}

.imgThumb {
    min-height: 170px;
    max-height: 170px;
    max-width: 170px;
    min-width: 170px;
}

.columnImg {
    /* float: left; */
}

.imageBox {
    /* padding: 10px; */
    /* padding-left: 10px; */
}

.imageGallary {
    overflow-y: scroll;
    max-height: 45vh;
    min-height: 45vh;
}

.uploadDiv {
    /* background-color: royalblue; */
    /* border-style:ridge; */
    margin-top: 10px;
}

.saveButton {
    margin-top: 20px;
}

.deleteProjectButton {
    float: right;
}

.reSequenceClass {
    float: right;
    /* background-color:#053365d1; */
    background-color: #355881;
    border-color: white;
    margin-left: 10px;
}

.buttonGrp {
    /* padding: 10px; */
    margin: 20px;
}

.modalBtnGrp {
    text-align: center;
}

.modalBodySort {
    overflow-y: scroll;
    max-height: 600px;
    min-height: 600px;
}

.reorderImg {
    /* z-index: 999999 !important; */
}

.disableContent {
    max-height: 50vh;
    min-height: 50vh;
}

.editAdminMain {
    margin-top: 30px;
    height: 100vh;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    line-height: 1.8em;
    color: black;
    /* text-align: center; */
    padding: 50px;
}

.tabContentMain {
    margin-top: 30px;
    /* padding: 30px; */
    /* background-color: sienna; */
    border: 1px solid black;
    height: 55vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 3vw;
    padding-right: 3vw;
    border-radius: 5px;
}

.card-header-tabs {
    border-bottom: 1px solid #dee2e6 !important;
}

.toggleLeft{
    text-align: left;
    margin-top: 0.5%;
}

.faIcon{
    margin-top: 10px;
}

.heading{
    font-weight: 900;
    text-align: center;
}

.otherCheck {
    transform: scale(1.50) !important;
}
.contactMain {
  height:70vh;
  margin:0;
  font-size:16px;
  /* font-family:"Lato", sans-serif; */
  font-family: 'Poppins', sans-serif;
  font-weight:400;
  line-height:1.8em;
  color:black;
}


.contactCont{
  /* background: red; */
  padding-top: 10vh;
  padding-bottom: 10vh;
  text-align: center;
}

.sendMessDiv{
  background:#e7e7e7;
  padding-top: 10vh;
  padding-bottom: 10vh;
  text-align: center;
}

.form-control{
  /* border: 1px solid #000000 !important; */
}

.contactUsheading{
  padding-bottom: 7vh;
  text-align: center;
}

.sendamessage{
  /* text-align: center; */
  /* padding-bottom: 5vh; */
}

.iconRow{
  text-align: center;
}

.iconHead{
  padding: 3vh 0vh 3vh 0vh;
  font-weight: 900;
  font-size: 1.5rem;
}

.iconContent{
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 20px;
}
/* .ptext{
  position:absolute;
  top:50%;
  width: 100%;
  text-align:center;
  color:#000;
  font-size:2vw;
  letter-spacing:8px;
  text-transform:uppercase;
} */


.sendMessage {
  color: #fff;
  background-color: black;
  border-color: black;
  margin-top: 5vh;
}
.sendMessage:hover {
  color: #fff;
  background-color: #e7e7e7;
  border-color: black;
  color: black;
}

.mapContainer{
  height: 100vh;;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mapContent{
  width: 80vw !important;
  height: 60vh !important;
  border-radius: 15px;
}

.addressContent{
  font-weight: 600;
}

.directionContent{
  text-decoration: underline;
}
@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
} 



.arrow {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -20px;
  width: 40px;
  height: 50px; 
  background-image: url(/static/media/down.e1927a5c.svg);
}

.bounce {
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

.adminMain {
    /* margin-top: 100px; */
    height: 100vh;
    font-size: 16px;
    /* font-family:"Lato", sans-serif; */
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    line-height: 1.8em;
    color: black;
    text-align: center;
    /* padding: 30px; */
}

.welcomeMsg{
    text-align: left;
}

.firstLineDiv{
    padding-bottom: 10px;
}

.alertClass{
    text-align: left;
}

.AccColorNotVisible{
    /* background-color:#e7e7e7; */
     background-color:#c2bdbd;
}


.AccColorNormal{
    /* background-color:#e7e7e7; */
    background-color:#053365d1;
    color: #fff;
    
}

.AccColorHeading{
    background-color:#606918d1;
    color: #fff;
    
}


.AccColorNormalTable{
    /* background-color:#e7e7e7; */
    background-color:#691818d1;
    color: #fff;
    
}

.AccColorAddNew{
    /* background-color:#e7e7e7; */
    background-color: #349a35;
    color: #fff;
    
}

.AccColorDanger{
    background-color:#dc3545;
    color: #fff;
}

.multiProjectButton{
    margin: 10px;
}

.faplus{
    margin-right: 10px;
}

.svg-inline--fa {
    vertical-align: -0.1em !important;
  }
.notie-container{font-size:1.6rem;height:auto;left:0;position:fixed;text-align:center;width:100%;z-index:100;box-sizing:border-box;-o-box-shadow:0 0 5px 0 rgba(0,0,0,.5);-ms-box-shadow:0 0 5px 0 rgba(0,0,0,.5);box-shadow:0 0 5px 0 rgba(0,0,0,.5)}@media screen and (max-width:900px){.notie-container{font-size:1.4rem}}@media screen and (max-width:750px){.notie-container{font-size:1.2rem}}@media screen and (max-width:400px){.notie-container{font-size:1rem}}.notie-background-success{background-color:#57bf57}.notie-background-warning{background-color:#d6a14d}.notie-background-error{background-color:#e1715b}.notie-background-info{background-color:#4d82d6}.notie-background-neutral{background-color:#a0a0a0}.notie-background-overlay{background-color:#fff}.notie-textbox{color:#fff;padding:20px}.notie-textbox-inner{margin:0 auto;max-width:900px}.notie-overlay{height:100%;left:0;opacity:0;position:fixed;top:0;width:100%;z-index:1}.notie-button{cursor:pointer}.notie-button,.notie-element{color:#fff;padding:10px}.notie-element-half{width:50%}.notie-element-half,.notie-element-third{display:inline-block;box-sizing:border-box}.notie-element-third{width:33.3333%}.notie-alert{cursor:pointer}.notie-input-field{background-color:#fff;border:0;font-family:inherit;font-size:inherit;outline:0;padding:10px;text-align:center;width:100%;box-sizing:border-box}.notie-select-choice-repeated{border-bottom:1px solid hsla(0,0%,100%,.2);box-sizing:border-box}.notie-date-selector-inner{margin:0 auto;max-width:900px;-webkit-user-select:none;-ms-user-select:none;-o-user-select:none;user-select:none}.notie-date-selector-inner [contenteditable],.notie-date-selector-inner [contenteditable]:focus{outline:0 solid transparent}.notie-date-selector-up{transform:rotate(180deg)}

.loginMain {
    margin-top: 100px;
    height: 100vh;
    font-size: 16px;
    /* font-family: "Lato", sans-serif; */
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    line-height: 1.8em;
    color: black;
}



.login-form {
    
    /* margin: 50px auto; */
    line-height: 3;
    /* position: absolute; */
    /* width: 80%; */
    /* margin-left: 10%; */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 70px;
}

.login-form form {
    margin-bottom: 15px;
    background: #ffffff;
    box-shadow: -1px 5px 10px rgba(0, 0, 0, 0.3);
    padding: 30px;
    font-size: 16px;
    /* font-family: 'Varela Round', sans-serif; */
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    line-height: 1.8em;
    color: black;
    border-radius: 25px;
}

.login-form h2 {
    margin: 0 0 15px;
}

.form-control, .btn {
    min-height: 38px;
    border-radius: 2px;
}

.btn {
    font-size: 15px;
    font-weight: bold;
}
.companyName{
    color: #0C1482;
    text-align: center;
    padding: 10px 0px 30px 0px;
}

.webSiteLink{
    text-align: center;
    margin-top: 30px;
}
.adminMain {
    margin-top: 100px;
    height:100vh;
    font-size:16px;
    /* font-family:"Lato", sans-serif; */
    font-family: 'Poppins', sans-serif;
    font-weight:400;
    line-height:1.8em;
    color:black;
    text-align: center;
    }

    .svg-inline--fa {
        vertical-align: -0.1em !important;
      }
.notdeveloped {
    font-size: 36px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    line-height: 1.8em;
    border-color: red;
    padding: 20px;
    border-width: 30px;
    border-style: solid;
    border-radius: 20px;
    background-color: #F4F4F4;
}

.notdevelopedOut {
    border-color: white;
    /* padding: 20px; */
    border-width: 10px;
    border-style: solid;
    border-radius: 30px;
    background-color: #F4F4F4;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.notdeveloped:hover {
    border-width: 50px;
    /* border thickness */
    border-color: white;
    background-color: white;
    /* try red, orange, ... */
}
.notdevelopedOut:hover {
    border-color: orangered;
}

.pimg404 {
    position: relative;
    opacity: 1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    /*
          fixed = parallax
          scroll = normal
        */
    background-attachment: scroll;
    min-height: 100vh;
    max-width: 100%;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    
    
    
}

.notFoundLogo{
    padding: 10px;
    height: 10vh;
    /* width: 10vw; */
}
.notdeveloped{
     /* antimated trantitions for all elements */
  /* For Safari 3.1 to 6.0 */
  transition: 0.5s;
}
