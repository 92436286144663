.contactMain {
  height:70vh;
  margin:0;
  font-size:16px;
  /* font-family:"Lato", sans-serif; */
  font-family: 'Poppins', sans-serif;
  font-weight:400;
  line-height:1.8em;
  color:black;
}


.contactCont{
  /* background: red; */
  padding-top: 10vh;
  padding-bottom: 10vh;
  text-align: center;
}

.sendMessDiv{
  background:#e7e7e7;
  padding-top: 10vh;
  padding-bottom: 10vh;
  text-align: center;
}

.form-control{
  /* border: 1px solid #000000 !important; */
}

.contactUsheading{
  padding-bottom: 7vh;
  text-align: center;
}

.sendamessage{
  /* text-align: center; */
  /* padding-bottom: 5vh; */
}

.iconRow{
  text-align: center;
}

.iconHead{
  padding: 3vh 0vh 3vh 0vh;
  font-weight: 900;
  font-size: 1.5rem;
}

.iconContent{
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 20px;
}
/* .ptext{
  position:absolute;
  top:50%;
  width: 100%;
  text-align:center;
  color:#000;
  font-size:2vw;
  letter-spacing:8px;
  text-transform:uppercase;
} */


.sendMessage {
  color: #fff;
  background-color: black;
  border-color: black;
  margin-top: 5vh;
}
.sendMessage:hover {
  color: #fff;
  background-color: #e7e7e7;
  border-color: black;
  color: black;
}

.mapContainer{
  height: 100vh;;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mapContent{
  width: 80vw !important;
  height: 60vh !important;
  border-radius: 15px;
}

.addressContent{
  font-weight: 600;
}

.directionContent{
  text-decoration: underline;
}

@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
} 



.arrow {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -20px;
  width: 40px;
  height: 50px; 
  background-image: url('../../assets/Images/contact/down.svg');
}

.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}
