.scrollTop {
    height: 20px;
    text-align: right;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 99999;
    cursor: pointer;  
  }
  
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }

  .scrollTop span {
    width: 50px;
    height: 50px;
    display: block;
    -webkit-transition: 1s;
    -moz-transition: 1s;
    transition: 1s;
    background: #002a57;
    border-radius: 40px;
    color: #fff;
    font-size: 28px;
    text-align: center;
    border: 2px solid #fff;
}
.scrollTop span:hover {
    background: #f1242b;
    color: #fff;
}