*:not(input) {
  user-select: none;
}

.section-hidden{
    display: none;
}

.LearnMore {
    /* background-color: white; */
    outline: none;
    cursor: pointer;
    border: 4px solid #002a57;
    color: #002a57; 
    letter-spacing: 4px;  
    font-size: 0.7em;
    padding: 5px 20px;
    border-radius: 10px;
    margin-top: 2vh;
    margin-bottom: 1vh;
  }

  .learnMoreSubHeading{
    font-weight: bold;
    letter-spacing: 2px;
    padding: 2vh 0vh 2vh 0vh;
    font-size: 2rem !important;
  }
  

  .cardMain{
    position: absolute;
    width: 80%;
    margin-left: 10%;
    background:lightgray;
    top: 10%;
    padding: 2%;
  }

  .ptext-cardcontent{
    position: absolute;
    /* top: 20%; */
    width: 80%;
    /* margin-left: 10%; */
    text-align: center;
    font-size: 1.3em;
    /* font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif; */
    font-family: 'Poppins', sans-serif;
    /* font-weight: bold; */
    line-height: 40px;
    background-color: rgba(255,255,255,0.9);
    border-radius: 25px;
    color: #002a57;
    /* font-weight: 900; */
    
    padding: 30px 5px 30px 5px;
    max-height: 110vh;
    overflow: auto;
  }

.centreContent{
  height: 120vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.paraCss{
  text-align: justify;
  padding: 15px;
  text-indent: 4em;
  /* text-shadow: -1px 0#002a57, 0 1px white, 1px 0 white, 0 -1px white; */
}

  @media(max-width:768px){
    .ptext-cardcontent{
      font-size: 0.8em;
      line-height: 27px;
      padding: 1px 5px 17px 5px;
    }  
    .learnMoreSubHeading {
      font-size: 1.5em !important;
    }
  }