.biZcert{
    /* min-height: 80vh !important; */
  }
  
  .bizDiv{
    text-align: center;
  }
  .bizDiv img{
    width: 40vw;
    height: 100vh;
    border: 2px solid black;
  }
  @media(max-width:768px) {
    .bizDiv img{
      width: 80vw;
      height: 60vh;
    }
    .biZcert{
      min-height: 60vh !important;
    }
  }
