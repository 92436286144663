
.snip1321 {
  -webkit-perspective: 50em;
  perspective: 50em;
  -webkit-box-sizing: padding-box;
  box-sizing: padding-box;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  /* overflow-x: scroll; */
  margin: 10px 10px 10px 10px !important;
  
}

/* .snip1321 Img {
  max-width: 100%;
  vertical-align: top;
} */

.snip1321 figcaption {
  top: 50%;
  left: 20px;
  right: 20px;
  position: absolute;
  opacity: 0;
  z-index: 1;
}

/* .snip1321 h2,
.snip1321 h4 {
  margin: 0;
}
.snip1321 h2 {
  font-weight: 600;
}
.snip1321 h4 {
  font-weight: 400;
  text-transform: uppercase;
}
.snip1321 i {
  font-size: 32px;
} */

.snip1321:after {
  background-color: #ffffff;
  position: absolute;
  content: "";
  display: block;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  opacity: 0;
}

.snip1321 a {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: 1;
}

.snip1321:hover figcaption, .snip1321.hover figcaption {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 1;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.snip1321:hover:after, .snip1321.hover:after {
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
  opacity: 1;
}




/* @import url(https://fonts.googleapis.com/css?family=Raleway:400,200,300,800); */
.snip0015 {


  /* font-family: 'Raleway', Arial, sans-serif; */
  justify-content: center;
  color: #fff;
  position: relative;
  overflow: hidden;
  margin: 10px;
  min-height: 200px; 
  max-height: 200px;
  /* height: 200px; */
  width: 250px;
  background: #000000;
  text-align: center;
  
}
.snip0015 * {
  
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  
}
.snip0015 img {
  
  opacity: 1;
  width: 100%;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
  
}
.snip0015 figcaption {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 3em 3em;
  width: 100%;
  height: 100%;
 
}
.snip0015 figcaption::before {
  
  position: absolute;
  top: 10%;
  right: 10px;
  bottom: 10%;
  left: 10px;
  border-top: 1px solid rgb(14, 14, 14);
  border-bottom: 1px solid rgb(14, 14, 14);
  content: '';
  opacity: 0;
  /* background-color: #ffffff; */
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;

}
.snip0015 h2,
.snip0015 h5,
.snip0015 p {
  
  margin: 0 0 5px;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s,-webkit-transform 0.35s,-moz-transform 0.35s,-o-transform 0.35s,transform 0.35s;
}

.snip0015 h2 {
  
  word-spacing: -0.15em;
  font-weight: 300;
  text-transform: uppercase;
  -webkit-transform: translate3d(0%, 50%, 0);
  transform: translate3d(0%, 50%, 0);
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.snip0015 h2 span {
  font-weight: 800;
}
.snip0015 p {
  font-weight: 200	;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
.snip0015 a {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  /* color: #ffffff; */
}
.snip0015:hover img {
  opacity: 0.35;
 
  
}

.snip0015:hover figcaption h5 {
  opacity: 1;
  -webkit-transform: translate3d(0%, 0%, 0);
  transform: translate3d(0%, 0%, 0);
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
  /* color: black; */
}
.snip0015:hover figcaption h2 {
  opacity: 1;
  -webkit-transform: translate3d(0%, 0%, 0);
  transform: translate3d(0%, 0%, 0);
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
  /* color: black; */
}
.snip0015:hover figcaption p {
  opacity: 0.9;
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}
.snip0015:hover figcaption::before {
  background: rgba(255, 255, 255, 0);
  top: 30px;
  bottom: 30px;
  opacity: 1;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  /* color: red; */
  /* background-color: red; */
}




@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
@import url(https://fonts.googleapis.com/css?family=Teko:700);
.snip1543 {
  background-color: #fff;
  color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  margin: 10px;
  max-width: 200px;
  min-width: 250px;
  overflow: hidden;
  position: relative;
  text-align: left;
  width: 100%;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.snip1543 *,
.snip1543 *:before,
.snip1543 *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.45s ease;
  transition: all 0.45s ease;
}

.snip1543 img {
  backface-visibility: hidden;
  max-width: 100%;
  vertical-align: top;
}

.snip1543:before,
.snip1543:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: '';
  background-color: #002a57;
  opacity: 0.5;
  -webkit-transition: all 0.45s ease;
  transition: all 0.45s ease;
}

.snip1543:before {
  -webkit-transform: skew(30deg) translateX(-80%);
  transform: skew(30deg) translateX(-80%);
}

.snip1543:after {
  -webkit-transform: skew(-30deg) translateX(-70%);
  transform: skew(-30deg) translateX(-70%);
}

.snip1543 figcaption {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
  bottom: 0;
  padding: 25px 40% 25px 20px;
}

.snip1543 figcaption:before,
.snip1543 figcaption:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #002a57;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
  content: '';
  opacity: 0.5;
  z-index: -1;
}

.snip1543 figcaption:before {
  -webkit-transform: skew(30deg) translateX(-100%);
  transform: skew(30deg) translateX(-100%);
}

.snip1543 figcaption:after {
  -webkit-transform: skew(-30deg) translateX(-90%);
  transform: skew(-30deg) translateX(-90%);
}

.snip1543 h3,
.snip1543 p {
  margin: 0;
  opacity: 0;
  letter-spacing: 1px;
}

.snip1543 h3 {
  font-family: 'Teko', sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 1em;
  text-transform: uppercase;
}

.snip1543 p {
  font-size: 0.9em;
}

.snip1543 a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.snip1543:hover h3,
.snip1543.hover h3,
.snip1543:hover p,
.snip1543.hover p {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 0.9;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.snip1543:hover:before,
.snip1543.hover:before {
  -webkit-transform: skew(30deg) translateX(-20%);
  transform: skew(30deg) translateX(-20%);
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

.snip1543:hover:after,
.snip1543.hover:after {
  -webkit-transform: skew(-30deg) translateX(-10%);
  transform: skew(-30deg) translateX(-10%);
}

.snip1543:hover figcaption:before,
.snip1543.hover figcaption:before {
  -webkit-transform: skew(30deg) translateX(-40%);
  transform: skew(30deg) translateX(-40%);
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

.snip1543:hover figcaption:after,
.snip1543.hover figcaption:after {
  -webkit-transform: skew(-30deg) translateX(-30%);
  transform: skew(-30deg) translateX(-30%);
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}


/* Included color classes.. 
.red 
.blue 
.yellow 
*/

@import url(https://fonts.googleapis.com/css?family=Raleway:400,800);
.snip1104 {
font-family: 'Raleway', Arial, sans-serif;
position: relative;
overflow: hidden;
margin: 10px;
min-width: 320px;
max-width: 310px;
max-height: 220px;
/* width: 100%; */
background: #000000;
color: #ffffff;
text-align: center;
box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

.snip1104 * {
-webkit-box-sizing: border-box;
box-sizing: border-box;
-webkit-transition: all 0.4s ease-in-out;
transition: all 0.4s ease-in-out;
}

.snip1104 img {
max-width: 100%;
position: relative;
opacity: 0.4;
}

.snip1104 figcaption {
position: absolute;
top: 0;
left: 0;
bottom: 0;
right: 0;
}

.snip1104 h2 {
position: absolute;
left: 40px;
right: 40px;
display: inline-block;
background: #000000;
-webkit-transform: skew(-10deg) rotate(-10deg) translate(0, -50%);
transform: skew(-10deg) rotate(-10deg) translate(0, -50%);
padding: 12px 5px;
margin: 0;
top: 50%;
text-transform: uppercase;
font-weight: 400;
}

.snip1104 h2 span {
font-weight: 800;
}

.snip1104:before {
height: 100%;
width: 100%;
top: 0;
left: 0;
content: '';
background: #ffffff;
position: absolute;
-webkit-transition: all 0.3s ease-in-out;
transition: all 0.3s ease-in-out;
-webkit-transform: rotate(110deg) translateY(-50%);
transform: rotate(110deg) translateY(-50%);
}

.snip1104 a {
left: 0;
right: 0;
top: 0;
bottom: 0;
position: absolute;
z-index: 1;
}

.snip1104.blue {
background: #123851;
}

.snip1104.blue h2 {
background: #0a212f;
}

.snip1104.red {
background: #581a14;
}

.snip1104.red h2 {
background: #36100c;
}

.snip1104.yellow {
background: #7f5006;
}

.snip1104.yellow h2 {
background: #583804;
}

.snip1104:hover img,
.snip1104.hover img {
opacity: 1;
-webkit-transform: scale(1.1);
transform: scale(1.1);
}

.snip1104:hover h2,
.snip1104.hover h2 {
-webkit-transform: skew(-10deg) rotate(-10deg) translate(-150%, -50%);
transform: skew(-10deg) rotate(-10deg) translate(-150%, -50%);
}

.snip1104:hover:before,
.snip1104.hover:before {
-webkit-transform: rotate(110deg) translateY(-150%);
transform: rotate(110deg) translateY(-150%);
}



@import url(https://fonts.googleapis.com/css?family=Playfair+Display:400,800);
@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
.snip1401 {
font-family: 'Playfair Display', Arial, sans-serif;
position: relative;
overflow: hidden;
margin: 10px;
min-width: 230px;
max-width: 315px;
max-height: 220px;
width: 100%;
color: #000000;
text-align: right;
font-size: 16px;
background-color: #000000;
}
.snip1401 * {
-webkit-box-sizing: border-box;
box-sizing: border-box;
-webkit-transition: all 0.35s ease;
transition: all 0.35s ease;
}
.snip1401 img {
max-width: 100%;
backface-visibility: hidden;
}
.snip1401 figcaption {
position: absolute;
top: 0;
bottom: 0;
right: 0;
z-index: 1;
opacity: 1;
padding: 30px 0 30px 10px;
background-color: #ffffff;
width: 40%;
-webkit-transform: translateX(150%);
transform: translateX(150%);
}
.snip1401 figcaption:before {
position: absolute;
top: 50%;
-webkit-transform: translateY(-50%);
transform: translateY(-50%);
right: 100%;
content: '';
width: 0;
height: 0;
border-style: solid;
border-width: 120px 120px 120px 0;
border-color: transparent #ffffff transparent transparent;
}
.snip1401:after {
position: absolute;
bottom: 50%;
right: 40%;
content: '';
width: 0;
height: 0;
border-style: solid;
border-width: 120px 120px 0 120px;
border-color: rgba(255, 255, 255, 0.5) transparent transparent transparent;
-webkit-transform: translateY(-50%);
transform: translateY(-50%);
-webkit-transition: all 0.35s ease;
transition: all 0.35s ease;
}
.snip1401 h3,
.snip1401 p {
line-height: 1.5em;
-webkit-transform: translateX(-30px);
transform: translateX(-30px);
margin: 0;
}
.snip1401 h3 {
margin: 0 0 5px;
line-height: 1.1em;
font-weight: 900;
font-size: 1.4em;
opacity: 0.75;
}
.snip1401 p {
font-size: 0.8em;
}
.snip1401 i {
position: absolute;
bottom: 0;
left: 0;
padding: 20px 30px;
font-size: 44px;
color: #ffffff;
opacity: 0;
}
.snip1401 a {
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
z-index: 1;
}
.snip1401:hover img,
.snip1401.hover img {
zoom: 1;
filter: alpha(opacity=50);
-webkit-opacity: 0.5;
opacity: 0.5;
}
.snip1401:hover:after,
.snip1401.hover:after,
.snip1401:hover figcaption,
.snip1401.hover figcaption,
.snip1401:hover i,
.snip1401.hover i {
-webkit-transform: translateX(0);
transform: translateX(0);
opacity: 1;
}

@import url(https://fonts.googleapis.com/css?family=Raleway);
.snip1584 {
font-family: 'Raleway', sans-serif;
position: relative;
display: inline-block;
overflow: hidden;
margin: 10px;
min-width: 230px;
max-width: 315px;
width: 100%;
color: #ffffff;
font-size: 16px;
text-align: left;
}
.snip1584 * {
-webkit-box-sizing: border-box;
box-sizing: border-box;
-webkit-transition: all 0.25s ease;
transition: all 0.25s ease;
}
.snip1584:before {
position: absolute;
top: 10px;
bottom: 10px;
left: 10px;
right: 10px;
top: 100%;
content: '';
background-color: rgba(51, 51, 51, 0.9);
-webkit-transition: all 0.25s ease;
transition: all 0.25s ease;
-webkit-transition-delay: 0.25s;
transition-delay: 0.25s;
}
.snip1584 img {
vertical-align: top;
max-width: 100%;
backface-visibility: hidden;
}
.snip1584 figcaption {
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
z-index: 1;
align-items: center;
display: flex;
flex-direction: column;
justify-content: center;
}
.snip1584 h3,
.snip1584 h5 {
margin: 0;
opacity: 0;
letter-spacing: 1px;
}
.snip1584 h3 {
-webkit-transform: translateY(-100%);
transform: translateY(-100%);
text-transform: uppercase;
font-weight: 400;
-webkit-transition-delay: 0.05s;
transition-delay: 0.05s;
margin-bottom: 5px;
}
.snip1584 h5 {
font-weight: normal;
background-color: #ae895d;
padding: 3px 10px;
-webkit-transform: translateY(-100%);
transform: translateY(-100%);
-webkit-transition-delay: 0s;
transition-delay: 0s;
}
.snip1584 a {
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
z-index: 1;
}
.snip1584:hover:before,
.snip1584.hover:before {
top: 10px;
-webkit-transition-delay: 0s;
transition-delay: 0s;
}
.snip1584:hover h3,
.snip1584.hover h3,
.snip1584:hover h5,
.snip1584.hover h5 {
-webkit-transform: translateY(0);
transform: translateY(0);
opacity: 1;
}
.snip1584:hover h3,
.snip1584.hover h3 {
-webkit-transition-delay: 0.3s;
transition-delay: 0.3s;
}
.snip1584:hover h5,
.snip1584.hover h5 {
-webkit-transition-delay: 0.2s;
transition-delay: 0.2s;
}

@import url(https://fonts.googleapis.com/css?family=Open+Sans:600;);
.snip1573 {
background-color:white;
/* display: inline-block; */
font-family: 'Open Sans', sans-serif;
font-size: 16px;
margin: 10px;
/* max-width: 250px; */
/* min-width: 250px; */
max-height: 25vh;
min-height: 25vh;
overflow: hidden;
position: relative;
text-align: center;
  /* align-items: center; */
justify-content: center;
/* width: 100%; */
}
/* @media(min-width:576px) {
.snip1573 {
max-height: 20vh;
min-height: 20vh;
}
} */

@media (min-width: 992px){
  .snip1573 {
      max-height: 10vw;
      min-height: 10vw;
      min-width: 10vw;
      max-width: 10vw;
  }
}
@media (max-width:556px) {
  .snip1573 {
  max-height: 35vw;
  min-height: 35vw;
  min-width: 35vw;
  max-width: 35vw;
  }
}
@media( min-width:556px) and (max-width:992px){
  .snip1573 {
  max-height: 25vw;
  min-height: 25vw;
  min-width: 25vw;
  max-width: 25vw;
  }
}
.snip1573 * {
-webkit-box-sizing: border-box;
box-sizing: border-box;
-webkit-transition: all 0.35s ease;
transition: all 0.35s ease;
}

.snip1573:before,
.snip1573:after {
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
-webkit-transition: all 0.35s ease;
transition: all 0.35s ease;
background-color:  #002a57;
border-left: 3px solid #fff;
border-right: 3px solid #fff;
content: '';
opacity: 0.9;
z-index: 1;
}

.snip1573:before {
-webkit-transform: skew(45deg) translateX(-155%);
transform: skew(45deg) translateX(-155%);
}

.snip1573:after {
-webkit-transform: skew(45deg) translateX(155%);
transform: skew(45deg) translateX(155%);
}

.snip1573 img {
backface-visibility: hidden;
max-width: 100%;
vertical-align: top;
}

.snip1573 figcaption {
top: 50%;
left: 50%;
position: absolute;
z-index: 2;
-webkit-transform: translate(-50%, -50%) scale(0.5);
transform: translate(-50%, -50%) scale(0.5);
opacity: 0;
-webkit-box-shadow: 0 0 10px #000000;
box-shadow: 0 0 10px #000000;
}

.snip1573 h3 {
background-color:  #002a57;
border: 2px solid #fff;
color: #fff;
font-size: 0.8em;
font-weight: 600;
letter-spacing: 1px;
margin: 0;
padding: 5px 10px;
text-transform: uppercase;
}

.snip1573 a {
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
z-index: 3;
}

.snip1573:hover > img,
.snip1573.hover > img {
opacity: 0.5;
}

.snip1573:hover:before,
.snip1573.hover:before {
-webkit-transform: skew(45deg) translateX(-55%);
transform: skew(45deg) translateX(-55%);
}

.snip1573:hover:after,
.snip1573.hover:after {
-webkit-transform: skew(45deg) translateX(55%);
transform: skew(45deg) translateX(55%);
}

.snip1573:hover figcaption,
.snip1573.hover figcaption {
-webkit-transform: translate(-50%, -50%) scale(1);
transform: translate(-50%, -50%) scale(1);
opacity: 1;
}
