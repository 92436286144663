.Toolbar {
    height: 56px;
    width: 100vw;
    max-width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #e7e7e7;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 90;
    background-image: -webkit-linear-gradient(20deg, #e7e7e7 50%, rgba(0, 0, 0, 0) 0%);
    BACKGROUND-COLOR: WHITE;
    transition: top 0.4s;
}

.Toolbar-hidden {
    top: -56px !important;
}

.Toolbar nav {
    height: 100%;
}

.Logo {
    position: absolute;
    right: 0;
    margin-right: 20px;
    height: 50px;
}
.companyNameMenu{
    position: absolute;
    right: 0;
    margin-right: 100px;
    /* height: 60px; */
    color: #0C1482;
    text-align: center;
    font-size: 1.75rem;
    font-family: 'Poppins', sans-serif;
}

.logoutButton{
    position: absolute;
right: 0;
margin-right: 100px;
}

@media (max-width: 1024px) {
    .DesktopOnly {
        display: none;
    }
    .companyNameMenu{
        display: none;
    }
}


