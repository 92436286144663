.FooterMain {
    background-color: #002a57;
    color: #ffffff;
    padding-top: 70px;
    /* padding-bottom: 100px; */
}

.certFooterDiv {
    /* padding-top: 50px; */
}

.certTittle {
    font-size: 26px;
    /* margin-top: 30px; */
    /* top: 10px; */
    /* position: relative; */
    font-weight: bold;
    color: white;
    /* margin-left: 40px; */
    /* margin-bottom: 20px; */
    display: inline-block;
}

.footerCertImg {
    background-color: white;
    /* height: 120px; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    /* overflow: hidden; */
    /* max-width:20%; */
}

.footerCertImgDiv {
    /* padding: 40px; */
    padding-top: 20px;
    padding-bottom: 20px;
    /* padding-right: 1vw; */
}

.copyWrite {
    text-align: center;
    padding: 5px;
    
    color: white;
    font-size: 0.7rem;
}

.copyWriteAdmin {
    text-align: center;
    padding: 15px;
    background-color: white;
    color: black;
}

.footerRight {
    /* background-color: violet; */
    float: right;
}

.footerLeft {
    /* background-color: teal; */
    float: left;
}

.addressText {
    padding-left: 15px;
}

/* .backtop {
    text-align: right;
    padding: 20px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 99999;
    display: block;
}
.backtop a {
    width: 40px;
    height: 40px;
    display: block;
    -webkit-transition: 1s;
    -moz-transition: 1s;
    transition: 1s;
    background: #005baa;
    border-radius: 40px;
    color: #fff;
    font-size: 28px;
    text-align: center;
}
.backtop a:hover {
    background: #f1242b;
    color: #fff;
} */

.FooterLogo {
    padding: 5px;
}

.footerName {
    align-items: right;
    font-size: 1.5em;
}

.footerIconDiv {
    /* background-color: yellow; */
    overflow: hidden;
}

.footerIconDiv1 {
    /* background-color: red; */
}

.footerCompanyName{
    font-size: 1.75rem !important;
}
.footerQoute {
    color: greenyellow;
    font-size: 1.25rem;
}

.certRowFooter {
    padding: 10px;
}

/* ipad and phone */
@media(max-width:992px) {
    
    
}


/* phone only */
@media(max-width:576px) {
    .footerCompanyName{
        font-size: 1em !important;
    }
    .footerQoute{
        font-size: 0.80rem !important;
    }
    .footerCDHeading{
        font-size: 1.6rem !important;
        padding-top: 5vw;
    }
    .footerCertHeader{
        font-size: 1.6rem !important;
    }
}

.brdr {
    width: 100%;
    height: 1px;
    background: white;
    margin: 20px 0;
    color: #ffffff;
}

.socalIcons{
   padding-left: 10px;
   
}

.FooterMain p{
    font-size: 13px;
    line-height: 20px;
}

.FooterMain ul {
    list-style: none;
    padding: 0px 0px 0px 0px;
}
.FooterMain li a,.FooterMain a  {
    font-size: 13px;
    transition: .5s all ease;
    text-decoration: none;
    
}

.ftrLink {
    color: white;
    font-size: 15px;
}
.ftrMainlink {
    font-size: 18px !important;
    text-transform: uppercase;
}

.redtest{
    border-style: solid;
  border-color: coral;
}

.ftrLinkSer{
    color: white;
    font-size: 15px;
}

.ftrLinkSer:hover{
    color: #007bff !important;
    cursor: pointer;
}