.adminMain {
    /* margin-top: 100px; */
    height: 100vh;
    font-size: 16px;
    /* font-family:"Lato", sans-serif; */
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    line-height: 1.8em;
    color: black;
    text-align: center;
    /* padding: 30px; */
}

.welcomeMsg{
    text-align: left;
}

.firstLineDiv{
    padding-bottom: 10px;
}

.alertClass{
    text-align: left;
}

.AccColorNotVisible{
    /* background-color:#e7e7e7; */
     background-color:#c2bdbd;
}


.AccColorNormal{
    /* background-color:#e7e7e7; */
    background-color:#053365d1;
    color: #fff;
    
}

.AccColorHeading{
    background-color:#606918d1;
    color: #fff;
    
}


.AccColorNormalTable{
    /* background-color:#e7e7e7; */
    background-color:#691818d1;
    color: #fff;
    
}

.AccColorAddNew{
    /* background-color:#e7e7e7; */
    background-color: #349a35;
    color: #fff;
    
}

.AccColorDanger{
    background-color:#dc3545;
    color: #fff;
}

.multiProjectButton{
    margin: 10px;
}

.faplus{
    margin-right: 10px;
}

.svg-inline--fa {
    vertical-align: -0.1em !important;
  }