.adminMain {
    margin-top: 100px;
    height:100vh;
    font-size:16px;
    /* font-family:"Lato", sans-serif; */
    font-family: 'Poppins', sans-serif;
    font-weight:400;
    line-height:1.8em;
    color:black;
    text-align: center;
    }

    .svg-inline--fa {
        vertical-align: -0.1em !important;
      }