.projectMain {
  height:100vh;
  margin:0;
  font-size:16px;
  /* font-family:"Lato", sans-serif; */
  font-family: 'Poppins', sans-serif;
  font-weight:400;
  line-height:1.8em;
  color:#002a57 !important;
  }
  .moreProjectTable{
     padding-top: 10vh;
     padding-bottom: 10vh;
  }

  .myColheight{
    max-height: 80vh;
    min-height: 80vh;
  }

  .pcardPara2{
    /* font-weight: bold; */
     /* min-height: 70vh; */
     overflow: scroll;
    max-height: 75vh;
    /* min-height: 75vh; */
     
}

.pcardCont{
  overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    max-height: 80vh;
    min-height: 80vh;
}
 .pimgProject{
  position:relative;
  opacity:1;
  background-position:center;
  background-size:cover;
  background-repeat:no-repeat;
  /* max-height:70vh;
  min-height:70vh; */
  background-attachment:fixed;
  max-width: 100vw;
 }


 .carousel-control-next-icon, .carousel-control-prev-icon {
  
  width: 50px !important;
  height: 50px !important;
}

@media(min-width:769px) and (max-width:1024px){
  .pimg , .pimgProject {
    background-attachment: scroll;
  }
}
@media(max-width:1366px) and (max-height:905px){
  .pimg , .pimgProject {
    background-attachment: scroll;
  }
}
@media(max-width:768px) {
  .pimg , .pimgProject {
    background-attachment: scroll;
  }
}