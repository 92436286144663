.Modal {
    z-index: 200;
    background-color: white;
    width: 70%;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    left: 15%;
    /* top: 30%; */
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    border-radius: 10px;
    max-height: 90vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.mainModal{
    z-index: 500;
    position: fixed;
    height: 100vh;
    width: 100vw;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: none;
}

@media (min-width: 768px) {
    .Modal {
        width: 70%;
        left: 15%;
    }
}
@media(max-width:769px){
    .Modal {
        width: 90vw;
        left: 5vw;
        margin-top: 23vw;
        max-height: 80vh;
        overflow-x: hidden;
        overflow-y: auto;
    }
}

@media(max-width:350px){
    .Modal {
        width: 90vw;
        left: 5vw;
        margin-top: 23vw;
        max-height: 80vh;
        overflow-x: hidden;
        overflow-y: auto;
    }
}

.reorderListItem{
    border: 1px solid #002a57;
}

.RearrangeProjectsHeading{
    /* background-color:#606918d1; */
    /* color: #fff; */
    /* border: 1px solid black; */
    background-image: -webkit-linear-gradient(20deg, #e7e7e7 50%, rgba(0, 0, 0, 0) 0%);
}

.reSortmodalCss{
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    line-height: 1.8em;
    color:  #002a57;
    
}