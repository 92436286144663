.Backdrop{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.9);
    overflow: hidden;
    visibility: hidden;
}

.initialBackDropClose{
    visibility: hidden;;
}

.backdropOpen{
    visibility: visible;
    -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 0.5s; /* Firefox < 16 */
        -ms-animation: fadein 0.5s; /* Internet Explorer */
         -o-animation: fadein 0.5s; /* Opera < 12.1 */
            animation: fadein 0.5s;
}

.backdropClose{
-webkit-animation: fadeout 0.5s; 
-moz-animation: fadeout 0.5s;
 -ms-animation: fadeout 0.5s; 
  -o-animation: fadeout 0.5s; 
     animation: fadeout 0.5s;
     visibility: hidden;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}


@keyframes fadeout {
    from { opacity: 1;  visibility: visible;}
    to   { opacity: 0;  visibility: hidden;}
}

/* Firefox < 16 */
@-moz-keyframes fadeout {
    from { opacity: 1;  visibility: visible;}
    to   { opacity: 0;  visibility: hidden;}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
    from { opacity: 1;  visibility: visible;}
    to   { opacity: 0;  visibility: hidden;}
}

/* Internet Explorer */
@-ms-keyframes fadeout {
    from { opacity: 1;  visibility: visible;}
    to   { opacity: 0;  visibility: hidden;}
}

/* Opera < 12.1 */
@-o-keyframes fadeout {
    from { opacity: 1;  visibility: visible;}
    to   { opacity: 0;  visibility: hidden;}
}


@keyframes fadeout {
    from { opacity: 1;  visibility: visible;}
    to   { opacity: 0;  visibility: hidden;}
}