.SideDrawer {
    position: fixed;
    width: 280px;
    max-width: 70%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: #e7e7e7;
    padding: 32px 16px;
    box-sizing: border-box;
  }

.initial-close{
    display: none;
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes open-sidebar {
    from {
        left: -100%;
    }      
    to{
        left: 0%;      
    }
}

/* Standard syntax */
@keyframes open-sidebar {
    from {
        left: -100%;
    }      
    to{
        left: 0%;   
    }
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes close-sidebar {
    from {
        left: 0px;
    }      
    to{
        left: 0px;    
    }
}

/* Standard syntax */
@keyframes close-sidebar {
    from {
        left: 0px;
    }      
    to{
        left: 0px;     
    }
}

@media (min-width: 1025px) {
    .SideDrawer {
        display: none;
    }
}

.Open {
    transform: translateX(0);
    -webkit-animation-name: open-sidebar; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 0.5s; /* Safari 4.0 - 8.0 */
    animation-name: open-sidebar;
    animation-duration: 0.5s;    
}

.Close {
    transform: translateX(-100%);
    -webkit-animation-name: close-sidebar; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 0.5s; /* Safari 4.0 - 8.0 */
    animation-name: close-sidebar;
    animation-duration: 0.5s;     
}

/* .Logo {
    height: 11%;
    margin-bottom: 32px;
} */