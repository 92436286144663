.notdeveloped {
    font-size: 36px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    line-height: 1.8em;
    border-color: red;
    padding: 20px;
    border-width: 30px;
    border-style: solid;
    border-radius: 20px;
    background-color: #F4F4F4;
}

.notdevelopedOut {
    border-color: white;
    /* padding: 20px; */
    border-width: 10px;
    border-style: solid;
    border-radius: 30px;
    background-color: #F4F4F4;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.notdeveloped:hover {
    border-width: 50px;
    /* border thickness */
    border-color: white;
    background-color: white;
    /* try red, orange, ... */
}
.notdevelopedOut:hover {
    border-color: orangered;
}

.pimg404 {
    position: relative;
    opacity: 1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    /*
          fixed = parallax
          scroll = normal
        */
    background-attachment: scroll;
    min-height: 100vh;
    max-width: 100%;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    
    
    
}

.notFoundLogo{
    padding: 10px;
    height: 10vh;
    /* width: 10vw; */
}
.notdeveloped{
     /* antimated trantitions for all elements */
  -webkit-transition: 0.5s;
  /* For Safari 3.1 to 6.0 */
  transition: 0.5s;
}