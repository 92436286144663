.stepImage {
    background: white;
}

.pcard {
    /* overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center; */
    /* padding-top: 10vh; */
    /* padding-bottom: 10vh; */
    /* height: 100vh; */
}

.pcard2 {
    /* padding-top: 10vh;
     padding-bottom: 10vh; */
    /* min-height: 60vh;
     max-height: 60vh; */
}

.pcardPara {
    /* background:#e7e7e7 ; */
    min-height: 80vh;
    font-weight: bold;
}

.pimg-plain {
    background-color: rgba(0, 0, 0, 0.5);
}

.row {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.projectCardEditMainClass{
    /* padding-bottom: 20px; */
}

.tryCss {
    /* background-color:cyan; */
    border: black;
    border-style: groove;
}

.imgThumb {
    min-height: 170px;
    max-height: 170px;
    max-width: 170px;
    min-width: 170px;
}

.columnImg {
    /* float: left; */
}

.imageBox {
    /* padding: 10px; */
    /* padding-left: 10px; */
}

.imageGallary {
    overflow-y: scroll;
    max-height: 45vh;
    min-height: 45vh;
}

.uploadDiv {
    /* background-color: royalblue; */
    /* border-style:ridge; */
    margin-top: 10px;
}

.saveButton {
    margin-top: 20px;
}

.deleteProjectButton {
    float: right;
}

.reSequenceClass {
    float: right;
    /* background-color:#053365d1; */
    background-color: #355881;
    border-color: white;
    margin-left: 10px;
}

.buttonGrp {
    /* padding: 10px; */
    margin: 20px;
}

.modalBtnGrp {
    text-align: center;
}

.modalBodySort {
    overflow-y: scroll;
    max-height: 600px;
    min-height: 600px;
}

.reorderImg {
    /* z-index: 999999 !important; */
}

.disableContent {
    max-height: 50vh;
    min-height: 50vh;
}

.editAdminMain {
    margin-top: 30px;
    height: 100vh;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    line-height: 1.8em;
    color: black;
    /* text-align: center; */
    padding: 50px;
}

.tabContentMain {
    margin-top: 30px;
    /* padding: 30px; */
    /* background-color: sienna; */
    border: 1px solid black;
    height: 55vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 3vw;
    padding-right: 3vw;
    border-radius: 5px;
}

.card-header-tabs {
    border-bottom: 1px solid #dee2e6 !important;
}

.toggleLeft{
    text-align: left;
    margin-top: 0.5%;
}

.faIcon{
    margin-top: 10px;
}

.heading{
    font-weight: 900;
    text-align: center;
}

.otherCheck {
    transform: scale(1.50) !important;
}