.LearnMoreLogo{
    align-items: center;
    height: 6vw;
    width: 8vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
  
}

.learnMoreHeading{
    text-align: center;
    font-weight: bold;
    letter-spacing: 2px;
    padding: 2vh 0vh 3vh 0vh;
    font-size: 2em;
    /* font-size: 3vw; */
    font-family: 'Poppins', sans-serif;
    /* font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif; */
}
.learnMoreContent{
    padding: 0px 50px 0px 50px;
    /* font-size: 1vw; */
    font-family: 'Poppins', sans-serif;
    /* font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif; */
}
.bizSafeLogo{
    margin-left: auto;
    height: 7vh;
    width: 14vw;
    float: right;
}
.closeBtn {
    background-color: white;
    outline: none;
    cursor: pointer;
    border: 4px solid #002a57;
    color: #002a57;   
    font-size: 1.2em;
    padding: 15px 30px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    letter-spacing: 2px;
    margin-top: 3vh;
  }
@media (max-width: 768px) {
    .learnMoreContent{
        padding: 0px 6px 0px 6px;
        font-size: 3vw;
        /* font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif; */
        font-family: 'Poppins', sans-serif;
    }
    .LearnMoreLogo{
        height: 18vw;
        width: 25vw;
    }
    .learnMoreHeading{
        font-size: 1.5em;
    }
    .bizSafeLogo{
        width: 30vw;
    }
    .closeBtn{
        padding: 0px 30px;
    }
}
button:focus {outline:0 !important;}