.loginMain {
    margin-top: 100px;
    height: 100vh;
    font-size: 16px;
    /* font-family: "Lato", sans-serif; */
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    line-height: 1.8em;
    color: black;
}



.login-form {
    
    /* margin: 50px auto; */
    line-height: 3;
    /* position: absolute; */
    /* width: 80%; */
    /* margin-left: 10%; */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 70px;
}

.login-form form {
    margin-bottom: 15px;
    background: #ffffff;
    box-shadow: -1px 5px 10px rgba(0, 0, 0, 0.3);
    padding: 30px;
    font-size: 16px;
    /* font-family: 'Varela Round', sans-serif; */
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    line-height: 1.8em;
    color: black;
    border-radius: 25px;
}

.login-form h2 {
    margin: 0 0 15px;
}

.form-control, .btn {
    min-height: 38px;
    border-radius: 2px;
}

.btn {
    font-size: 15px;
    font-weight: bold;
}
.companyName{
    color: #0C1482;
    text-align: center;
    padding: 10px 0px 30px 0px;
}

.webSiteLink{
    text-align: center;
    margin-top: 30px;
}